import React from 'react';
import { Typography, Container, Grid, Box } from '@mui/material';
import { FullpageSection } from '@ap.cx/react-fullpage';
import greenSvg from '../SVG/greenBack.svg'
import ChatBot from '../chatbot';
import { Chat } from '@mui/icons-material';
import {styled} from "@mui/system"
// import greenBack from '../SVG/greenBack';
const Image = styled('img')({

});
const SectionOne = () => {
    return (
        <>
        {/* <FullpageSection style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}> */}
        <Box style={{ height: '100vh', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Container component="main" sx={{ flex: 1 }} >
                <Grid container justifyContent="center" alignItems="center" sx={{ textAlign: 'center',height:'90%' }}>
                    <Grid item xs={12}>
                        <Image src='sectionOneAssets.png' sx={{filter:'blur(2px)', maxWidth:{lg:'100%',  md:'60%', xs : '50%'}, height: 'auto' ,}} alt="Section One" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Blinker',lineHeight:'30px', fontWeight:400, fontSize: { xs: '25px', md: '40px' } }}>
                            OUR STRENGTH IS IN OUR DEDICATED TEAMS,
                        </Typography>
                        <Typography variant="h4" component="h2" gutterBottom sx={{ fontFamily: 'Blinker', lineHeight:'30px',fontWeight:400, fontSize: { xs: '25px', md: '40px' } }}>
                        WHICH ARE LIGHT, AGILE AND IMPACTFUL.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'flex-start',
                mb:{xs:10},
                mt:-10
            }}>
            <Image src='sec2.svg'  sx={{
                width:{md:'856px' , xs : '481px'},
                height:{md:'545px' , xs : '307px'}
            }}></Image>
            </Container>
           
        {/* </FullpageSection> */}
        {/* <ChatBot></ChatBot> */}
        </Box>
        </>
    );
};

export default SectionOne;
