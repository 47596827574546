import React, { useEffect, useState } from'react';
import { BrowserRouter, Route, Routes , useLocation } from'react-router-dom';
import Nav from './Components/nav';
import Home from './Components/Static/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import "@fontsource/blinker/800.css";
import "@fontsource/blinker/600.css";
import "@fontsource/blinker/400.css";
import "@fontsource/blinker/200.css";
import "@fontsource/blinker/700.css";
import Footer from './Components/mainFooter';
import PostGrid from './Components/Posts';
import PostDetails from './Components/PostDetails';
import Works from './Components/Works';
import WorksSingle from './Components/WorksSingle'
import ReactGA from 'react-ga4';
import RuxIndex from './Components/Works/ruxIndex';
import GetQuote from './Components/GetQuote';
import Dentist from './Components/Works/dentist/Dentist';
import TagManager from 'react-gtm-module'
import Ticket from './Components/Ticket';
import ChatBot from './Components/chatbot';
import Services from './Components/services/index';
import './App.css'
import SectionThree from './Components/services/SectionThree';
import SectionFour from './Components/services/SectionFour';
import SectionFive from './Components/services/SectionFive';
import Campaign from "./Components/campaign";
import okDialog from './Components/okDialog';
import UserData from './Components/campaign/userData';
import Thank from './Components/campaign/thanksPage';
import { useMediaQuery,useTheme  } from '@mui/material';
ReactGA.initialize('G-69RRJC9W8H');

const Layout = ({ children }) => {
  const location = useLocation();
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  React.useEffect(() => {
    if (location.pathname === '/' && !isXs) {
      document.body.classList.add('no-overflow');
    } else {
      document.body.classList.remove('no-overflow');
      
    }
  }, [location]);

  return (
    <div className="layout">
      {children}
    </div>
  );
};

const App = () => {

  const [currentPage, setCurrentPage] = useState(0);
  const resetCurrentPage = () => {
    setCurrentPage(0);
  };
  const tagManagerArgs = {
    gtmId: 'GTM-WTZKX3NK',
  }
  
  TagManager.initialize(tagManagerArgs)
  return (
    <BrowserRouter>
      <Nav onLogoClick={resetCurrentPage}/>
      
      <MainContent currentPage={currentPage} setCurrentPage={setCurrentPage}/>
      
    </BrowserRouter>
  );
};

const MainContent = ({ currentPage, setCurrentPage }) => {
  const location = useLocation();
  // usePageViews()

  return (
    <Layout>
    {/* <ChatBot></ChatBot> */}
    
      <Routes>
        <Route path="/" exact element={<Home currentPage={currentPage} setCurrentPage={setCurrentPage}/>} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blogs" element={<PostGrid />} />
        <Route path="/blogs/:id" element={<PostDetails />} />
        <Route path="/case-studies" element={<Works />} />
        <Route path="/case-studies/:id" element={<WorksSingle />} />
        <Route path="/case-studies/rux" element={<RuxIndex />} />
        <Route path="/case-studies/dentist" element={<Dentist />} />
        <Route path="/quote" element={<GetQuote />} />
        <Route path="/technical-support" element={<Ticket />} />
        <Route path="/chat" element={<ChatBot />} />
        <Route path="service" element={<Services></Services>}></Route>
        <Route path="services/1" element={<SectionThree></SectionThree>}></Route>
        <Route path="services/2" element={<SectionFour></SectionFour>}></Route>
        <Route path="services/3" element={<SectionFive></SectionFive>}></Route>
        <Route path="campaign/seo" element={<Campaign></Campaign>}></Route>
        <Route path="campaign/submit" element={<UserData></UserData>}></Route>
        <Route path="campaign/thanks" element={<Thank></Thank>}></Route>
      </Routes>
      {location.pathname !== '/' && <Footer />}
    </Layout>
  );
};

export default App;

