import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { styled } from '@mui/system'
import { IconBrandTwitterFilled, IconBrandLinkedin, IconBrandFacebookFilled } from '@tabler/icons-react';
import ServiceComponent from './serviceSectionComponent';
const About = () => {
  const items = [
    { imageUrl: 'prof2.png', role: 'CEO & Co-Founder', name: 'Mohammad Behroyan' },
    { imageUrl: 'prof4.png', role: 'CTO & Co-Founder', name: 'Tom Soltani' },
    { imageUrl: 'prof6.png', role: 'Team Lead', name: 'Sam Namirani' },
    { imageUrl: 'prof3.png', role: 'CMO', name: 'Max Heidari' },
    { imageUrl: 'prof5.png', role: 'Brand Manager', name: 'Ella Amiri' },
    { imageUrl: 'prof.png', role: 'Animation Director', name: 'Anjela Valy' }
  ];

  const Texts = [" We only engage if we can add value: If a company already has a solid marketing strategy or has reached its full potential, we won't engage. We prioritize impact over opportunity."
    , "We operate with fairness and integrity: Our business practices are built on ethical foundations, prioritizing fairness to all stakeholders and meeting our high moral standards – beyond what's legally required."
    , " We deliver exceptional value: Our products and services are designed to be beneficial, useful, effective, and exceed client expectations."]
  const Image = styled('img')({

  });

  return (
    <>

    
      <Grid container spacing={2} sx={{
        overflow:'hidden',
        height: 'auto',
        alignItems: 'center',
        padding: '10px',

      }}>
        <Grid item xs={12} md={6} sx={{
          textAlign: 'left',
          height:'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'hidden'
        }}>
          <Box>
            <Typography variant="h2" gutterBottom sx={{
              fontFamily: "Blinker",
              fontWeight: '800',
              fontSize: { xs: '40px', md: '64px', lg: '72px' },
              my: 5,
              // Responsive font size
              lineHeight: '40px'
            }}>
              Our Story
            </Typography>
            <Typography variant="body1" sx={{
  fontFamily: "Blinker",
  fontWeight: '400',
  fontSize: { xs: '15px', md: '24px' },
  width: { xs: '100%', md: '400px' }, // Responsive width
  lineHeight: '36px',
  mb: 4
}}>
  <strong>Beverly</strong> was founded by three friends from diverse backgrounds united in our passion for digital marketing. Prior to starting Beverly, we learned first-hand, based on our <strong>50 years of combined business experience</strong>, that building a business that offers an exceptional product or service isn't enough to succeed. You must also effectively communicate what you offer to your potential clients. This insight led us to establish Beverly with a simple yet ambitious mission: <strong>“to help start-ups reach their full potential."</strong>
</Typography>
<Typography variant="h5" gutterBottom sx={{
  fontFamily: "Blinker",
  fontWeight: '400',
  fontSize: { xs: '15px', md: '24px' },
  width: { xs: '100%', md: '400px' }, // Responsive width
  lineHeight: '36px'
}}>
  Since our inception, we have been guided by <strong>three core principles</strong> that drive us to fulfill our mission:
</Typography>


          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{
          position: 'relative',
          // overflow: 'hidden',
          height:'auto',
          zIndex: -1,
          mt: { md: -100 }
        }}>
          <img src='aboutImage.png' alt="About" style={{
            position: 'absolute',
            right: 0,

            width: '100%',
            height: 'auto'
          }} />
        </Grid>

        <Grid item lg={12} sx={{
          mt:{md:10 , xs:60},
          display: 'flex',
          flexDirection: {md:'column',xs:'row'},
          alignItems: 'center',
          width:'100%',
          justifyContent:'center',
          ml:{md:0}
        }}>
          <Grid container spacing={4} sx={{
            maxWidth: '1200px',
            width:'100%',
            display: 'flex',
            flexDirection: {md:'row',xs:'column'},
            alignContent: 'center',
            alignItems:'center',
            justifyContent:'center'
          }}>
            {Texts.map(x => (
              <Grid item lg={4} md={6} xs={12}>
                <Box sx={{
                  width: {md:'343px', xs:'260px'}
                  , height: {md:'350px',xs:'270px'},
                  boxShadow: '0 0 30px #e2e4eb',
                  backgroundColor: '#fff',
                  borderRadius: '30px',
                  alignContent: 'center'
                }}>
                  <Typography sx={{
                    p: 4,
                    fontSize: {md:'20px', xs:'12px'},
                    fontFamily:'blinker',
                    lineHeight:'38px',
                    fontWeight:'400'

                  }}>{x}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{
        
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        alignContent: 'center',
        // mt: { lg: 30, md: 2, sm: 25 },
        mb: 4,
        flexWrap: 'nowrap',
      }}>
        <Grid item xs={12}>
          <Typography sx={{
            mt: { md: '150px', xs: '100px' },
            fontFamily: "Blinker",
            fontWeight: '800',
            fontSize: { xs: '40px', md: '64px', lg: '72px' }, // Responsive font size
            textAlign: 'center',
            lineHeight:'91%'
          }}>
            Our Team<br />
            We are the remote-natives
          </Typography>
        </Grid>
        <Grid container sx={{
          display: 'flex',
          maxWidth: '1000px',
          justifyContent: 'center',
          textAlign: 'center',
          height: '80%',
          mt: { lg: 3, md: 12, sm: 20, xs: 10 }
        }}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={12} md={6} lg={4} key={index} sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
              height: '360px'
            }}>
              <Box sx={{
                boxShadow: '1px 1px 5px rgba(0,0,0,0.3)',
                backgroundColor: '#fff',
                borderRadius: '10px',
                width: '300px',
                padding: '24px',

                textAlign: 'center'
              }}>
                <Image src={item.imageUrl} alt={`Profile ${index + 1}`} sx={{
                  width: '156px',
                  height: '156px',
                  borderRadius: '50%',
                  objectFit: 'cover'
                }} />
                <Typography sx={{
                  mt: 2,
                  fontFamily: "Blinker",
                  fontWeight: '600',
                  fontSize: { xs: '15px', md: '24px' },
                }}>
                  {item.name}
                </Typography>
                <Typography sx={{
                  fontFamily: "Blinker",
                  fontWeight: '400',
                  fontSize: { xs: '12px', md: '20px' },
                }} color={'#7a7d9c'}>
                  {item.role}
                </Typography>
                <Grid container sx={{
                  mt: { lg: 5, md: 0, sm: 5, xs: 4 }
                }}><Grid item lg={4} xs={4}><IconBrandFacebookFilled color='#1877f2' /></Grid><Grid item lg={4} xs={4}><IconBrandLinkedin color='#0074b4' /></Grid>
                  <Grid item lg={4} xs={4}><IconBrandTwitterFilled color='lightblue' /></Grid> </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* <CountdownTimer targetDate="8/20/2024"></CountdownTimer> */}
    </>
  );
};

export default About;
