// src/EnterCode.js
import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import axios from 'axios';
import CustomButton from '../CustomButton';

function CodeComponent() {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleSearch = async () => {
    try {
      const response = await axios.get(`${apiUrl}/ticket/${code}`);
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error fetching data');
    }
  };

  return (
    <div style={{
      marginBottom:20,
      textAlign:"center"
    }}>
      <TextField
      sx={{
        backgroundColor:'white',
      }}
        label="Enter Code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        fullWidth
        margin="normal"
      />
      <CustomButton variant="contained" color="primary" clicked={handleSearch} text="Search" />
        
      {message && (
        <Typography sx={{
          color:'ButtonText',
          py:2
        }} variant="body1" color="textSecondary" style={{ marginTop: '1rem' , fontFamily:'blinker',fontSize : {md:'24px',xs:'16px'}}}>
        Status Message :  {message}
        </Typography>
      )}
    </div>
  );
}

export default CodeComponent;
