import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Chat';
import '../Library/css/chat.css'
import CustomButton from './CustomButton';
import { TextField } from '@mui/material';
import OkDialog from './okDialog';
import axios from 'axios';
import { Dangerous } from '@mui/icons-material';

<style>

</style>
const ChatBot = () => {
  const initialData = {
    "questions": [
      {
        "id": 1,
        "text": "Welcome! What type of services are you looking for from our digital marketing agency?",
        "options": [
          {
            "text": "Branding",
            "next": [
              {
                "id": 2,
                "text": "What type of branding services do you need?",
                "options": [
                  {
                    "text": "Logo development and design",
                    "next": [
                      {
                        "id": 3,
                        "text": "Would you also like to receive branding strategy consultation?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just logo design and visual identity" }
                        ]
                      }
                    ]
                  },
                  {
                    "text": "Complete visual identity design",
                    "next": [
                      {
                        "id": 3,
                        "text": "Would you also like to receive branding strategy consultation?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just logo design and visual identity" }
                        ]
                      }
                    ]
                  },
                  {
                    "text": "Brand strategy consultation",
                    "next": [
                      {
                        "id": 4,
                        "text": "Would you also like to receive advertising and marketing services for your brand?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just branding" }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "text": "SEO",
            "next": [
              {
                "id": 2,
                "text": "What type of website needs SEO?",
                "options": [
                  {
                    "text": "Corporate website",
                    "next": [
                      {
                        "id": 3,
                        "text": "What is your main focus in SEO?",
                        "options": [
                          { "text": "On-page content optimization" },
                          { "text": "Link building" },
                          { "text": "Analytics and reporting" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you like to receive monthly reports and SEO analysis?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just optimization and link building" }
                        ]
                      }
                    ]
                  },
                  {
                    "text": "Online store",
                    "next": [
                      {
                        "id": 3,
                        "text": "What is your main focus in SEO?",
                        "options": [
                          { "text": "On-page content optimization" },
                          { "text": "Link building" },
                          { "text": "Analytics and reporting" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you like to receive monthly reports and SEO analysis?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just optimization and link building" }
                        ]
                      }
                    ]
                  },
                  {
                    "text": "Personal blog",
                    "next": [
                      {
                        "id": 3,
                        "text": "What is your main focus in SEO?",
                        "options": [
                          { "text": "On-page content optimization" },
                          { "text": "Link building" },
                          { "text": "Analytics and reporting" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you like to receive monthly reports and SEO analysis?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just optimization and link building" }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "text": "Website design",
            "next": [
              {
                "id": 2,
                "text": "What type of website do you need?",
                "options": [
                  {
                    "text": "Corporate website",
                    "next": [
                      {
                        "id": 3,
                        "text": "Do you need content creation for your website?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just website design" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you also like to receive SEO services?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just website design" }
                        ]
                      }
                    ]
                  },
                  {
                    "text": "Online store",
                    "next": [
                      {
                        "id": 3,
                        "text": "Do you need content creation for your website?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just website design" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you also like to receive SEO services?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just website design" }
                        ]
                      }
                    ]
                  },
                  {
                    "text": "Personal website or blog",
                    "next": [
                      {
                        "id": 3,
                        "text": "Do you need content creation for your website?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just website design" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you also like to receive SEO services?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just website design" }
                        ]
                      }
                    ]
                  },
                  {
                    "text": "Service website",
                    "next": [
                      {
                        "id": 3,
                        "text": "Do you need content creation for your website?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just website design" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you also like to receive SEO services?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just website design" }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "text": "Content creation",
            "next": [
              {
                "id": 2,
                "text": "What type of content do you need?",
                "options": [
                  {
                    "text": "Articles and blogs",
                    "next": [
                      {
                        "id": 3,
                        "text": "Would you also like to receive content strategy consultation?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just content creation" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you like to receive SEO services for the created content?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just content creation" }
                        ]
                      }
                    ]
                  },
                  {
                    "text": "Social media content",
                    "next": [
                      {
                        "id": 3,
                        "text": "Would you also like to receive content strategy consultation?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just content creation" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you like to receive SEO services for the created content?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just content creation" }
                        ]
                      }
                    ]
                  },
                  {
                    "text": "Videos and animations",
                    "next": [
                      {
                        "id": 3,
                        "text": "Would you also like to receive content strategy consultation?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just content creation" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you like to receive SEO services for the created content?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just content creation" }
                        ]
                      }
                    ]
                  },
                  {
                    "text": "Podcasts and audio files",
                    "next": [
                      {
                        "id": 3,
                        "text": "Would you also like to receive content strategy consultation?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just content creation" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you like to receive SEO services for the created content?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just content creation" }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "text": "Advertising campaign",
            "next": [
              {
                "id": 2,
                "text": "What type of advertising campaign do you need?",
                "options": [
                  {
                    "text": "Google AdWords",
                    "next": [
                      {
                        "id": 3,
                        "text": "Would you like to receive analysis and reporting of campaign results?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just campaign execution" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you also like to receive branding or SEO services?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just the advertising campaign" }
                        ]
                      }
                    ]
                  },
                  {
                    "text": "Social media advertising",
                    "next": [
                      {
                        "id": 3,
                        "text": "Would you like to receive analysis and reporting of campaign results?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just campaign execution" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you also like to receive branding or SEO services?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just the advertising campaign" }
                        ]
                      }
                    ]
                  },
                  {
                    "text": "Display and banner advertising",
                    "next": [
                      {
                        "id": 3,
                        "text": "Would you like to receive analysis and reporting of campaign results?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just campaign execution" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you also like to receive branding or SEO services?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just the advertising campaign" }
                        ]
                      }
                    ]
                  },
                  {
                    "text": "Email marketing",
                    "next": [
                      {
                        "id": 3,
                        "text": "Would you like to receive analysis and reporting of campaign results?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just campaign execution" }
                        ]
                      },
                      {
                        "id": 4,
                        "text": "Would you also like to receive branding or SEO services?",
                        "options": [
                          { "text": "Yes, I need it" },
                          { "text": "No, just the advertising campaign" }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  };
  const postData = () => {
    const phoneRegex = /^\d{10,12}$/;  // Regular expression for 10 to 12 digit phone numbers

    if (!phone) {
        setError('Phone number is required');
        return;
    }

    if (!phoneRegex.test(phone)) {
        setError('Phone number must be 10 to 12 digits');
        return;
    }

    setButtonClicked(true);
    axios.post('https://api.beverly.digital/api/guest', {
        phone,
        content: 'from chatbot',
        email: 'from chat bot'
    }).then(res => {
        setOpen(true);
        setButtonClicked(false);
    }).catch(err => {
        setError('An error occurred. Please try again later.');
        setButtonClicked(false);
    });
};
  const [last, setLast] = useState(false);
  const [history, setHistory] = useState([]);
  const [error, setError] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(initialData.questions[0]);
  const [isOpen, setIsOpen] = useState(false);
  const [open,setOpen] = useState(false)
  const [phone,setPhone] = useState('')
  const handleOpen = () => setIsOpen(true);
  const [buttonClicked, setButtonClicked] = useState(false)
  const handleClose = () => {
    setIsOpen(false)
    
  };

  const closeModal = () => {
    setOpen(false)
    setIsOpen(false)
  }
  
  const handleOptionClick = (option) => {
    const nextQuestion = option.next ? option.next[0] : null;
    setHistory([...history, currentQuestion.text]);
    if (nextQuestion) {
      setCurrentQuestion(nextQuestion);
    } else {
      setLast(true)
    }
  };

  return (
    <>
    
      <Fab
        color="primary"
        aria-label="chat"
        onClick={handleOpen}
        sx={{
          position : 'fixed',
          left:{lg:'95%', xs:'50%'},
          top:{lg:'90%', xs : '90%'}
        }}
        // className="floating-button"
      >
        <AddIcon />
      </Fab>
      <Modal
      style={{
        background:'rgb(255 255 255 / 0%)'
      }}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="modal-box" sx={{
          display:'flex',
          flexDirection:'column',
          justifyContent:'space-evenly',
          left:{lg:'85%', xs:'50%'},
          top:{lg:'60%', xs : '60%'}
        }}>
          {last && <>
            <Typography sx={{
              mb : 2
            }}  helperText={error} id="modal-title" variant="h6" component="h2">
          Enter your Phone, We will send message to you
          </Typography>

          <TextField sx={{
            MozAppearance:'textfield'
          }}  type='number' placeholder='Phone' error={Boolean(error)} onChange={(e) =>{ 
            setPhone(e.target.value)
          setError('')}}></TextField>
          {error && 
            <Typography mt={-5} color='red'>{error}</Typography>
          }
          <CustomButton disabled={buttonClicked} clicked={postData} text={'submit'}></CustomButton>
          <OkDialog OpenModal={open} clicked={closeModal} />
          </>}
          {!last && <><Typography id="modal-title" variant="h6" component="h2">
          {currentQuestion.text}
          </Typography>

          {currentQuestion.options.map((option, index) => (
          <CustomButton text={option.text} key={index} clicked={() => handleOptionClick(option)} />
        ))}</>
      }
        </Box>
      </Modal>
      
    </>
  );
};

export default ChatBot;
