import { Box, Grid  } from "@mui/material";
import React from "react";
import {styled} from "@mui/system"
import imgColor from './assets/Color.png'
import imgIcons from './assets/icons.png'
import imglogo from './assets/logo.png'
import imgLap from './assets/lap.png'
import imgCap from './assets/cap.png'
import imglogokdjd from './assets/gif.gif'
import imgBag from './assets/bag.png'
import imgShirt from './assets/shirt.png'
import imgBoard from './assets/board.png'
import imgphone from './assets/phone.png'
import imgMug from './assets/mug.png'
import imgThanks from './assets/thanks.png'


import imgpersona from './assets/persona.png'

const Image = styled('img')({
    
});

const SectionTwo = () =>{
    return (
        <Box sx={{
            display:"flex",
            overflow:'hidden',
            flexDirection:'column',
            alignItems:'center',
            height:'100%'
        }}>
        <Grid  container spacing={5} sx={{
            width:'70%',
            mt:20
        }}>
            <Grid item lg={6} xs={12}>
                <Image sx={{
                    width:'100%'
                }} src={imgColor}></Image>
            </Grid>
            <Grid item lg={6} xs={12}>
                <Image sx={{
                    width:'100%'
                }}  src={imglogo}></Image>
            </Grid>
            <Grid item lg={6} xs={12}>
                <Image sx={{
                    width:'100%'
                }} src={imgIcons}></Image>
            </Grid>
            <Grid item lg={6} xs={12}>
                <Image sx={{
                    width:'100%'
                }} src={imgpersona}></Image>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Image sx={{
                    width:'100%',
                    height:{md:'89%' , xs:'100%'}
                }} src={imglogokdjd}></Image>
            </Grid>   

            <Grid item xs={12} lg={8}>
                <Image sx={{
                    width:'100%'
                }} src={imgLap}></Image>
            </Grid> 
        </Grid>
        <Grid sx={{
            width:'70%',
            mt:{lg:-10, xs:'5px'}
        }} container spacing={5}>
         <Grid sx={{
            
         }} item xs={12} lg={8}>
                <Image sx={{
                    width:'100%',
                    
                }} src={imgBag}></Image>
            </Grid>  
            <Grid item xs={12} lg={4}>
                <Image sx={{
                    width:'100%',
                    height:{md:'89%' , xs:'100%'}
                }} src={imgCap}></Image>
            </Grid> 

        </Grid> 

        <Grid sx={{
            width:'70%',
            mt:{lg:-10,xs:'5px'}

        }} container spacing={5}>
         <Grid item xs={12} lg={4}>
                <Image sx={{
                    width:'100%',
                    height:'85%'
                }} src={imgMug}></Image>
            </Grid>  
            <Grid item xs={12} sx={{
                mt:{xs:'5px'}
            }} lg={8}>
                <Image sx={{
                    width:'100%',
                    height:'85%'
                }} src={imgBoard}></Image>
            </Grid> 

        </Grid> 
        <Grid sx={{
            width:'70%',
            mt:{lg:-7,xs:1}
            
        }} container spacing={4}>  
        <Grid item xs={12} lg={6}>
                <Image sx={{
                    width:'100%',
                    
                }} src={imgphone}></Image>
            </Grid>  
            <Grid item xs={12} lg={6}>
                <Image sx={{
                    width:'100%',
                    
                }} src={imgShirt}></Image>
            </Grid> 

        </Grid> 
        <Grid sx={{
            width:'70%',
            

        }} container spacing={4}>
        <Grid item xs={12} lg={12}>
                <Image sx={{
                    width:'90%',
                    flexDirection:'column',
                    alignItems:'center',
                    mb:20
                }} src={imgThanks}></Image>
            </Grid>  


        </Grid>
        </Box>
    )
}
export default SectionTwo;