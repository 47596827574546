// src/components/PostGrid.js
import '../Library/css/Blogs.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton'; // Fixing Skeleton import
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

const PostGrid = () => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(apiUrl+'/posts?category=blogs')
      .then(response => {
        setPosts(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the posts!', error);
        setLoading(false);
      });
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight:'100vh',
        mt: 10,
        ml:{md:4, xs:'0'}
      }}
    >
      <Typography sx={{
        fontFamily:'blinker',
        fontSize:'48px',
        ml:-5
      }} mb={5}>
        Blog
      </Typography>
      <Grid
        container
        maxWidth={1200}
        sx={{
          height: 'auto',
          display: 'flex',
          

          flexDirection:{md : 'row',xs:'column'},
          // px: { xs: 0, md: 6 , lg:12, xl:20},
          alignItems:'center'

        }}
      >
        {loading ? (
          Array.from(new Array(6)).map((_, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              key={index}
              sx={{
                // Add extra margin to all items in the second column
                mb: 10,
              }}
            >
              <Card
                sx={{
                  p: 3,
                  backgroundColor: '#e5e5ea',
                  width: '75%',
                  borderRadius: 4,
                  boxShadow: 3,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
                className='customBlogCard'
              >
                <Skeleton variant="rectangular" height={250} />
                <CardContent sx={{ mt: 2 }}>
                  <Skeleton width="80%" />
                  <Skeleton width="60%" />
                  <Skeleton width="40%" />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          posts.map((post, index) => {
            const content = post.content;
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');
            const firstImage = post.banner;
            const description = doc.body.textContent.slice(0, 100) + '...';

            return (
              <Grid
                item
                xl={4}
                lg={4}
                md={6}
                xs={12}
                key={post.id}
                
                sx={{
                 // Add extra margin to all items in the second column
                  mb: 10,
                }}
              >
                <Link
                  style={{ textDecoration: 'none' }}
                  to={`/blogs/${post.id}`}
                >
                  <Card
                    sx={{
                      p: 3,
                      backgroundColor: '#e5e5ea',
                      width: '350px',
                      borderRadius: 4,
                      boxShadow: 3,
                      height: '495px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                    className='customBlogCard'
                  >
                    {firstImage && (
                      <CardMedia
                        component="img"
                        height="228"
                        width="310"
                        image={firstImage}
                        alt={post.title}
                        sx={{ borderRadius: 2 }}
                      />
                    )}
                    <CardContent sx={{ mt: 2 }}>
                      <Typography variant="h6" component="div" gutterBottom>
                        {post.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" mb={2}>
                        {description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            );
          })
        )}
      </Grid>
      <img
        src="halfcircleblogs.png"
        style={{
          position: 'fixed',
          zIndex: -1,
          bottom: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      />
    </Box>
  );
};

export default PostGrid;
