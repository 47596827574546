// Import necessary modules
import React from 'react';
import Button from '@mui/material/Button';

// Define the ButtonComponent
const CustomButton = ({ text,clicked,disabled }) => {
  return (
    <Button 

      variant='contained' 
      color='primary'
      onClick={clicked} 
      disabled={disabled}
      sx={{ textTransform: 'none', marginTop: 5 ,fontSize: {md:'16px' , xs : '12px'},fontFamily:'blinker' }}
    >
      {text}
    </Button>
  );
};

// Export the component for use in other parts of the app
export default CustomButton;
