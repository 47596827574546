import { Button, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import dentist from './Works/dentist/assets/dentBanner.png';
import rux from './Works/rux/assets/ruxBanner.png';
import love from './Works/love.png';
import dent from './Works/dent.png';
import design from './Works/design.png';
import movie from './Works/movie.png';
import '../Library/css/cases.css';

const Works = () => {
  const navigate = useNavigate();
  const [mEnter, setMEnter] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null); // State to track the hovered item

  const clicked = (item) => {
    console.log("Clicked");

    navigate(`/case-studies/${item.id}`, { state: { imageUrl: item.imageUrl } });
  };

  const [items] = useState([
    { id: 'dentist', imageUrl: dentist,text : 'Designing a Landing Page for a Dental Specialist in Toronto' ,title:'Dental'},
    { id: 'rux', imageUrl: rux,text : 'RUX Digital Marketing - Crafting a Cohesive Visual Brand Identity' ,title:'Rux Business'},
    { id: '1', imageUrl: dent ,text : 'Your Medical Exam Partner - Top 3% Success with Expert Guidance',title:'Medical Exam Partner'},
    { id: '2', imageUrl: movie,text : 'Ultimate Streaming - Watch Your Favorite Movies Anytime' ,title:'Ultimate Streaming'},
    { id: '3', imageUrl: design,text : 'Make Your Interior More Minimalistic & Modern' ,title:'Modern Minimalism'},
    { id: '4', imageUrl: love,text : 'Made with Love and a Strong Sense of Purpose' ,title:'Purposeful Crafting'},
  ]);

  return (
    <div style={{
      backgroundImage: 'url(worksBG.png)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '120vh',
      backgroundAttachment: 'fixed',
      backgroundPositionY: 'bottom',
      marginTop: '10px'
    }}>
      <Container sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        mt: 8
      }}>
        <Typography sx={{
          fontSize: '48px',
          fontFamily: 'blinker',
          marginBottom: '20px'
        }}>
          Case Studies
        </Typography>
        <Grid container spacing={15} sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 10,
          mb: '300px',
        }}>
          {items.map((item, index) => (
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}
              onMouseEnter={() => setHoveredItem(item.id)} // Set hovered item
              onMouseLeave={() => setHoveredItem(null)} // Clear hovered item
            >
              {index < 2 ? (
                <Link
                  to={{
                    pathname: `/case-studies/${item.id}`,
                    state: { item }
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <div style={{
                    position: 'relative',
                    width: '384px',
                    height: '301px',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',  // Center horizontally and vertically
                  }}>
                    <div className={hoveredItem === item.id ? 'imageCss' : ''} style={{
                      zIndex: 10,
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      bottom: '0',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      background: 'linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(0,212,255,0) 80%)',
                      color: '#7a7d9c',
                      border: 'none',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      textAlign:'center',
                      alignContent:'end',
                      
                    }}>
                      
                      </div>
                      <img
                      className={hoveredItem === item.id ? 'imgFocus' : ''}
                      style={{
                        filter: 'blur(4px)',
                        
                       
                        borderRadius: '10px',
                        width: '375px',
                        height: '290px',
                      }}
                      src={item.imageUrl}
                      alt=""
                    />
                    <div className={hoveredItem === item.id ? 'box' : ''} style={{
                      display:'flex',
                      flexDirection:'column',
                      alignItems:'center',
                      width:'167px',
                      height:'120px',
                      position: 'absolute',
                      bottom: '40%',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      backgroundColor:'#fff',
                      borderRadius:'20px',
                      padding:6,
                      justifyContent:'center'
                    }}>
                      <Typography sx={{
                        zIndex:4,
                        fontFamily:'blinker',
                        fontSize:'16px',
                        fontWeight:'600',
                        backgroundColor:'transparent',
                        color:'#000',
                        mb:4
                      }}>{item.title} </Typography>
                     
                    </div>
                    <Typography className={hoveredItem === item.id ? 'txt' : ''} sx={{
                        mb:3,
                        
                        display:'none',
                        color:'#fff',
                        fontFamily:'Blinker',
                        position: 'absolute',
                      bottom: '50%',
                      left: '50%',
                        width:'250px',
                        textAlign:'center',
                        transform: 'translateX(-50%)',
                      }}>
                      {item.text}
 
                      </Typography>
                    <Button className={hoveredItem === item.id ? 'btnFocus' : ''} style={{
                      
                      position: 'absolute',
                      bottom: '40%',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      backgroundColor: '#f0f0f0',
                      color: '#7a7d9c',
                      border: 'none',
                      padding: '10px 20px',
                      width:'167px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      backgroundColor: '#10F9B1',
                      color: '#000'
                    }}>
                      See More
                    </Button>
                  </div>
                </Link>
              ) : (
                <div style={{
                  position: 'relative',
                  width: '384px',
                  height: '301px',
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',  // Center horizontally and vertically
                }}>
                  <div className={hoveredItem === item.id ? 'imageCss' : ''} style={{
                      zIndex: 1,
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      bottom: '0',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      background: 'linear-gradient(360deg, rgba(255,255,255,0.7) 0%, rgba(0,212,255,0) 80%)',
                      color: '#7a7d9c',
                      border: 'none',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      color: '#000',
                      textAlign:'center',
                      alignContent:'end',
                      
                    }}>
                     
                      </div>
                    <img
                      className={hoveredItem === item.id ? 'imgFocus' : ''}
                      style={{
                        filter: 'blur(4px)',
                        
                       
                        borderRadius: '10px',
                        width: '375px',
                        height: '290px',
                      }}
                      src={item.imageUrl}
                      alt=""
                    />
                    <div className={hoveredItem === item.id ? 'box' : ''} style={{
                      display:'flex',
                      flexDirection:'column',
                      alignItems:'center',
                      width:'167px',
                      height:'120px',
                      position: 'absolute',
                      bottom: '40%',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      backgroundColor:'#fff',
                      borderRadius:'20px',
                      padding:6,
                      justifyContent:'center'
                    }}>
                      <Typography sx={{
                        zIndex:4,
                        fontFamily:'blinker',
                        fontSize:'16px',
                        fontWeight:'600',
                        color:'#000',
                        mb:4
                      }}>{item.title} </Typography>
                     
                    </div>
                    <Typography className={hoveredItem === item.id ? 'txt' : ''} sx={{
                        mb:3,
                        
                        display:'none',
                        color:'#fff',
                        fontFamily:'Blinker',
                        position: 'absolute',
                      bottom: '50%',
                      left: '50%',
                        width:'250px',
                        transform: 'translateX(-50%)',
                        textAlign:'center'
                      }}>
                      {item.text}
 
                      </Typography>
                    <Button className={hoveredItem === item.id ? 'btnFocus' : ''} style={{
                      
                      position: 'absolute',
                      bottom: '40%',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      backgroundColor: '#f0f0f0',
                      color: '#7a7d9c',
                      border: 'none',
                      padding: '10px 20px',
                      width:'167px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      backgroundColor: '#10F9B1',
                      color: '#000'
                    }}>
                      See More
                    </Button>
                </div>
              )}
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default Works;
