import React, { useState } from 'react';
import { TextareaAutosize, TextField, Typography } from '@mui/material';
import axios from 'axios';
import CustomButton from '../CustomButton';

function FormComponent() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleSubmit = async () => {
    const newErrors = {};
    
    // Email Regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    // Phone Regex (Accepts numbers, spaces, dashes, parentheses)
    const phoneRegex = /^[0-9\s\-().]+$/;
    
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    if (!title) newErrors.title = 'Title is required';
    if (!description) newErrors.description = 'Description is required';
    
    if (phone && !phoneRegex.test(phone)) {
      newErrors.phone = 'Invalid input. Please enter a valid number.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    try {
      setErrors({})
      const response = await axios.post(apiUrl+'/ticket/new', {
        title,
        description,
        phone,
        email
      });
      setMessage(response.data.code);
    } catch (error) {
      alert('Error submitting form');
    }
    setLoading(false);
  };

  return (
    <form style={{ textAlign: 'center' }}>
      <TextField
        sx={{
          backgroundColor:'white',
        }}
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.email}
        helperText={errors.email}
      />
      
      <TextField
      sx={{
        backgroundColor:'white',
      }}
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.title}
        helperText={errors.title}
      />
      <TextField
      sx={{
        backgroundColor:'white',
      }}
        multiline
        rows={4}
        maxRows={7}
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.description}
        helperText={errors.description}
      />
      <TextField
      sx={{
        backgroundColor:'white',
      }}
        label="Phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        fullWidth
        margin="normal"
        error={!!errors.phone}
        helperText={errors.phone}
      />
      <CustomButton disabled={loading} variant="contained" color="primary" text={"Submit"} clicked={handleSubmit} />

      {message && (
        <Typography 
          sx={{ color: 'ButtonText', py: 2 }} 
          variant="body1" 
          color="textSecondary" 
          style={{ marginTop: '1rem', fontFamily: 'blinker', fontSize: { md: '24px', xs: '16px' } }}
        >
          Status Code : {message}
        </Typography>
      )}
    </form>
  );
}

export default FormComponent;
