import { Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
const ContactUsButton = () => {
    return (<Button variant='contained' color='primary'>
        <NavLink style={{
          textDecoration: 'none',
          color:'black',
          fontFamily:'blinker',
          fontWeight:'600'
        }} to={"/quote"}>Get A Quote</NavLink>
        </Button>)
}
export default ContactUsButton