import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "../Library/css/footer.css";
import { styled } from "@mui/system";
import Lottie from "react-lottie";
import ham from "../lottie/ham";
import { NavLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import {
  IconBrandBehance,
  IconBrandDribbble,
  IconMail,
  IconPhone,
  IconLocation
} from "@tabler/icons-react";
const Image = styled("img")({});

const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "blinker",
  fontWeight: 200,
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px",
  },
}));
const A = styled("a")({});
function mainFooter() {
  const pages = [
    { name: "Our Services", to: "service" },
    { name: "Case Studies", to: "Case-Studies" },
    { name: "Blog", to: "blogs" },
    { name: "Technical Support", to: "Technical-Support" },
    { name: "About Us", to: "about" },
    { name: "Contact Us", to: "Contact" },
    
  ];
  const defaultOptions = {
    loop: true,
    autoplay: true,

    animationData: ham,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Grid container>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#000",
          textAlign: "center",
          zIndex: 1,
          overflow: "hidden",
          position: "sticky",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          
        }}
      >
        <Grid
          container
          sx={{
            mt: { lg: 10 },
            display: "flex",
            flexDirection: { lg: "row", md: 'row', xs: "row" },
            
          }}
        >
          <Grid
            container
            lg={5}
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: { lg: "flex-start", sm: "center", xs: "center" },
            }}
          >
            <Grid item lg={12} md={12} xs={12}>
              <Image
                sx={{
                  width: { md: "166px", xs: "92px" },
                  height: { md: "43px", xs: "30px" },
                }}
                src={"/logoW.png"}
                alt="logo"
              />
            </Grid>
            <Grid item lg={12}>
              <div  className="follow-us">
                <div style={{ display: "inline" }}>
                  <IconMail size={25} height={20} />
                  <A
                    style={{
                      display: "inline",
                      fontFamily: "blinker",
                      fontWeight: "800",
                    }}
                    href="mailto:info@beverly.digital"
                  >
                    <Text
                      sx={{
                        display: "inline",
                      }}
                    >
                      E-mail: info@beverly.digital
                    </Text>
                  </A>
                </div>
                <div>
                  {" "}
                  <IconPhone size={25} height={20} />
                  <A
                    style={{
                      display: "inline",
                      fontFamily: "blinker",
                      fontWeight: "800",
                    }}
                    href="tel:+16475599797"
                  >
                  <Text
                    sx={{
                      display: "inline",
                    }}
                  >
                    {" "}
                    Phone: +1647-559-9797
                  </Text>
                  </A>
                </div>
                <div>
                  {" "}
                  <IconLocation size={25} height={20} />
                  <A
                    style={{
                      display: "inline",
                      fontFamily: "blinker",
                      fontWeight: "800",
                    }}
                    href="https://g.co/kgs/EDVGHHB"
                  >
                  <Text
                    sx={{
                      display: "inline",
                    }}
                  >
                    {" "}
                    Corporate Head Office: <br />
                    7167 Yonge St, Thornhill, Ontario, Canada
                  </Text>
                  </A>
                </div>
                <Typography
                  sx={{
                    fontSize: { lg: "86px", sm: "50px", xs: "50px" },
                    fontFamily: "blinker",
                    fontWeight: "100",
                    color: "#40FAC1",
                  }}
                >
                  Follow Us
                </Typography>
                <ul>
                  <li>
                    <a
                      href="https://dribbble.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconBrandDribbble />
                    </a>
                    &ensp;
                    <a
                      href="https://linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedInIcon />
                    </a>
                    &ensp;
                    <a
                      href="https://instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon />
                    </a>
                    &ensp;
                    <a
                      href="https://behance.net"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconBrandBehance />
                    </a>
                    &ensp;
                    <a
                      href="https://facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookIcon />
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>

          <Grid
            lg={2}
            xs={12}
            md={12}
            sx={{
              mb: 4,
              textAlign: {lg:'left', md: "center", xs: "center" },
            }}
          >
            {pages.map((page) => (
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "navbar")}
                to={page.to}
                sx={{ display: "inline", color: "#555" }}
              >
                <Text
                  sx={{
                    color: "white",
                  }}
                >
                  {page.name}
                </Text>
                {/* <Box sx={{ mx: 2, my: 2}} className={({ isActive }) => (isActive ? "active" : "navbar")}>
                      
                    </Box> */}
              </NavLink>
            ))}
          </Grid>
          <Grid
            sx={{
              display: { md: "inline", xs: "none", sm: "none" },
            }}
            item
            lg={5}
            xs={12}
            md={12}
          >
            <Lottie options={defaultOptions} width={300} height={464}></Lottie>
          </Grid>

          <Grid
            sx={{
              display: { md: "none", xs: "inline" },
            }}
            item
            xs={12}
            md={12}
          >
            <Lottie options={defaultOptions} width={100} height={162}></Lottie>
          </Grid>
        </Grid>
        <img
          src="/Vector2.png"
          alt="Vector"
          style={{
            zIndex: -1,
            bottom: 0,
            right: 0,
            position: "absolute",
          }}
        />
      </Box>
    </Grid>
  );
}

export default mainFooter;
