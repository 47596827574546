import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link, NavLink} from 'react-router-dom';
import Menu from './hamMenu';
import '../Library/css/Navbar.css';
import Hamburger from 'hamburger-react';
import { useState } from 'react';
import CustomButton from './CustomButton';
import {styled} from '@mui/system';
import XsMenu from './XsMenu';
import LgMenu from './LgMenu';
import { Grid } from '@mui/material';

// const pages = [ {name : 'Work', to : 'work'},{name : 'About' , to : 'about'}, {name : 'Contact', to : 'Contact'},{name : 'Ticket', to : 'Ticket'}, {name : 'Blog', to : 'blogs'}];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const Image = styled('img')({

});
function ResponsiveAppBar({ onLogoClick }) {
  const [isOpen, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!isOpen);
  };
  return (
    <Container
      sx={{
        mb: {md:"80px",xs:'50px'}
        
      }}
    >
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: "#f2f4fc",
          color: "#000",
          zIndex: "500",
        }}
      >
        <Container maxWidth="xxl" sx={{
          display:'flex',
          alignItems:{xs:'none' ,md : 'center' },
          flexDirection:'column'
        }}>
            <Box sx={{ flexGrow: 0, display: {xs:'none' ,lg : 'flex' }, width:'100%'  ,alignItems:'center',justifyContent:'space-between' }  }>
            <Grid sx={{
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
    }}>
      <Link to="" onClick={onLogoClick}>
        <Image
        
          sx={{
            position: "relative",
            zIndex: "100000",
            width: { md: '166px', xs: '92px' },
            height: { md: '43px', xs: '30px' },
          }}
          src={"/logo.png"}
          alt="logo"
        />
      </Link>
      </Grid>
            <Box sx={{ flexGrow: 0, display: {xs:'none' ,lg : 'flex' } , alignItems:'center'}}>
            
            <LgMenu />
            </Box>
              <NavLink
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontFamily: "blinker",
                  fontWeight: "600",
                }}
                to={"quote"}
              >
                <Button
                  sx={{
                    fontFamily: "blinker",
                    fontWeight: "600",
                  }}
                  variant="contained"
                  color="primary"
                >
                  Get a Proposal
                </Button>
              </NavLink>
            </Box>

          <Box sx={{
            display: {xs:'flex' ,lg : 'none' },
          }}>
          <XsMenu />
          </Box>
            
        </Container>
      </AppBar>
    </Container>
  );
}
export default ResponsiveAppBar;