import '../Library/css/Navbar.css';

import { Box, Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { React, useState } from "react";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/system';

const LgMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pages = [
    { name: 'Case Studies', to: 'Case-Studies' },
    { name: 'Blog', to: 'blogs' },
    { name: 'Technical Support', to: 'Technical-Support' },
    { name: 'About Us', to: 'about' },
    { name: 'Contact Us', to: 'Contact' },
  ];

  const services = [
    { name: 'Our Services', to: '/service' },
    { name: 'Branding', to: '/services/1' },
    { name: 'Digital Performance', to: '/services/2' },
    { name: 'SEO', to: '/services/3' },
  ];

  const Image = styled('img')({});

  return (
    <>
    
      
      

      <Box sx={{ mx: 2, my: 2 }}>
        <IconButton
          id="services-button"
          aria-controls={open ? 'services-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          
        >
          <Typography sx={{
            fontFamily:'blinker',
            color:'#717389'
          }}>Services</Typography>
        </IconButton>
        <Menu
          id="services-menu"
          anchorEl={anchorEl}
          open={open}
          onClick={handleClose}
          MenuListProps={{
            'aria-labelledby': 'services-button',
          }}
        >
          {services.map((service) => (
            <MenuItem key={service.to} onClick={handleClose}>
              <NavLink
                
                to={service.to}
                className={({ isActive }) => (isActive ? "active" : "navbar")}
                sx={{ textDecoration: 'none', color: '#000' }}
              >
                {service.name}
              </NavLink>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {pages.map((page) => (
        <NavLink

          className={({ isActive }) => (isActive ? "active" : "navbar")}
          key={page.to}
          to={page.to}
          sx={{ display: 'block', fontFamily:'blinker', }}
        >
          <Box sx={{ mx: 2, my: 2,fontFamily:'blinker', }}>
            {page.name}
          </Box>
        </NavLink>
      ))}
      </>
  );
};

export default LgMenu;
