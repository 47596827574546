// Import necessary modules
import React from 'react';
import Button from '@mui/material/Button';

// Define the ButtonComponent
const QuoteButton = (props) => {
  return (
    <Button 
    {...props}
      variant='contained' 
      
    //   onClick={clicked} 
      sx={{ textTransform: 'none',fontFamily:'blinker',
        fontWeight:'800',
        textTransform: 'none', marginTop: 5 ,fontSize: {md:'16px' , xs : '12px'},fontFamily:'blinker',color:'#213e46',backgroundColor:'white' ,padding: '10px 20px',
        border:'1px solid #52efbe',borderRadius:'12px' ,'&:hover': {
    background: 'rgb(42, 231, 172)',color:'white'
  },'&:active ':{
    background: 'rgb(114, 135, 226)',color:'white'
  }}}
    />
   
  );
};

// Export the component for use in other parts of the app
export default QuoteButton;
