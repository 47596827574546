import "../Library/css/menu.css";

import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typograph,
  Typography,
  responsiveFontSizes,
} from "@mui/material";
import { Link, NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Lottie from "react-lottie";
import ham from "../lottie/ham";
import { styled } from "@mui/system";

const CustomLottie = styled(Lottie)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    display: "none",

    marginTop: theme.spacing(4),
  },
}));

const HamMenu = ({ isOpen, toggleMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const services = [
    { name: "Our Services", to: "/service" },
    { name: "Branding", to: "/services/1" },
    { name: "Digital Performance", to: "/services/2" },
    { name: "SEO", to: "/services/3" },
  ];

  const handleClickSub = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    handleClick();
    setAnchorEl(null);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,

    animationData: ham,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const pages = [
    { name: "Case Studies", to: "Case-Studies" },
    { name: "Blog", to: "blogs" },
    { name: "Technical Support", to: "technical-support" },
    { name: "About Us", to: "about" },

    { name: "Contact Us", to: "Contact" },
    // { name: 'Services', to: 'services' },
  ];

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleClick = (e) => {
    toggleMenu();
  };

  return (
    <div className={`menu-overlay ${isOpen ? "open" : "close"}`}>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "column" },
          alignItems: "center",
        }}
      >
        <Grid item sm={6}>
          <Box
            sx={{
              display: { md: "flex", xs: "none" },
              justifyContent: "center",
            }}
          >
            {/* <Box component="img" src='/menuImg.png' sx={{
              display:{
                xs:'none',
                md:'inline',
                lg:'inline',

              }
            }} height={464} alt="Menu"  />
           */}
            <CustomLottie
              options={defaultOptions}
              width={300}
              height={464}
            ></CustomLottie>
          </Box>
        </Grid>
        <Grid
          xs={12}
          sx={{
            display: { lg: "none", md: "none", xs: "inline" },
          }}
        >
          {/* <Link to='' ><img onClick={handleClick} src={isOpen  ? '/logoW.png' :'/logo.png' } alt='logo' style={{position:'relative',zIndex : '100000'}}/></Link> */}
        </Grid>
        <Grid item lg={6} xs sm={12} md={6}>
          <div
            className="menu-content"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <ul>
            <li>
                <IconButton
                  id="services-button"
                  aria-controls={open ? "services-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClickSub}
                >
                  <Typography
                    sx={{
                      fontFamily: "blinker",
                      fontSize: "36px",
                      fontWeight: "600",
                    }}
                    className="navbar"
                  >
                    Services
                  </Typography>
                  <ArrowDropDownIcon></ArrowDropDownIcon>
                </IconButton>
                <Menu
                  id="services-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClick={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "services-button",
                  }}
                >
                  {services.map((service) => (
                    <MenuItem
                      sx={{
                        backgroundColor: "#000",
                        width: "200px",
                        height: "30px",
                      }}
                      key={service.to}
                      onClick={handleClose}
                    >
                      <NavLink
                        to={service.to}
                        className={({ isActive }) =>
                          isActive ? "active" : "navbar"
                        }
                        sx={{ textDecoration: "none", color: "#000" }}
                      >
                        {service.name}
                      </NavLink>
                    </MenuItem>
                  ))}
                </Menu>
              </li>

              {pages.map((page) => (
                <li
                  key={page.to}
                  sx={{
                    flexGrow: 1,
                    display: {
                      xs: "none",
                      md: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active" : "navbar"
                    }
                    to={page.to}
                    sx={{ display: "inline", color: "#555" }}
                    onClick={handleClick}
                  >
                    <Typography
                      sx={{
                        fontFamily: "blinker",
                        fontSize: "36px",
                        fontWeight: "600",
                      }}
                    >
                      {page.name}
                    </Typography>
                    {/* <Box sx={{ mx: 2, my: 2}} className={({ isActive }) => (isActive ? "active" : "navbar")}>
                      
                    </Box> */}
                  </NavLink>
                </li>
              ))}
              
            </ul>
          </div>
        </Grid>
      </Grid>
      <div
        className="menu-close"
        onClick={toggleMenu}
        style={{ cursor: "pointer" }}
      >
        ×
      </div>
    </div>
  );
};

export default HamMenu;
