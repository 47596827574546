import { styled } from '@mui/system';
import React from 'react';
import pc from './assets/pc.png'
import back from './assets/back.png'
import back2 from './assets/back2.png'
import {  Grid, Typography } from '@mui/material';
// import back from '../../../../public/rux/back.png'
// import back2 from '../../../../public/rux/back2.png'

const Image = styled('img')({
    
});
const SectionOne = () => {
   
  return (
    <Grid container sx={{
      overflow:'hidden',
      height:'100vh',
      display:'flex',
      flexDirection:{xs:'column-reverse',lg:'row'},
      alignItems:'center',
      flexWrap:'nowrap'
      
    }}>
    <Grid item lg={4} xs={12} sx={{
      overflow:'hidden',
      
    }}>
    <Typography

    sx={{
      mt:{lg : 50 , xs : 0},
      ml:{lg : 50 , xs : 0},
      fontFamily:'blinker'
      ,fontSize:48
    }}
    >RUX</Typography>
    
    <Typography sx={{mt:{lg : 1 , xs : 0},
      ml:{lg : 50 , xs : 0},}}>
      VISUAL IDENTITY </Typography>

    </Grid>
    <Grid item lg={8} xs={12}>
      <Image sx={{
        overflow:'hidden',
        width:{lg : '1044.81px', md: '700px' , xs : '379px'},
        height:{lg : '765px' , xs : '278px'},
        mt:20,
        mb:20,

      }} src={pc}></Image>
      </Grid>
      <Image src={back} sx={{
        overflow:'hidden',
        display:'inline',
        position:'absolute',
        width:{lg : '1044.81px', md: '700px' , xs : '379px'},
        top:'20px',
        zIndex:-5
      }}></Image>
      <Image src={back2} sx={{
        overflow:'hidden',
        display:'inline',
        position:'absolute',
        top:'20px',
        width:{md: '1440px',xs:'400px'},
        zIndex:-5,
        right:2,
        height:'839px'

        
      }}></Image>

    </Grid>
  );
};

export default SectionOne;