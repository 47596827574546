import { Box, Container, Grid, Tooltip, Typography, tooltipClasses } from '@mui/material';

import ContactUsButton from '../ContactUsButton';
import { FullpageSection } from '@ap.cx/react-fullpage';
import React from 'react';
import TagManager from 'react-gtm-module'
import { styled } from '@mui/system';

const BrandingContainer = styled(Container)(({ theme }) => ({
    textAlign: 'left',
}));

const BrandingImage = styled('img')({
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    zIndex: '2',
    
});

const BrandingBack = styled('img')({
  width: '100%',
  maxWidth: '20%',
  height: 'auto',
  mt:50,
  zIndex: '2',
});

const BrandingList = styled('ul')({
    listStyle: 'none',
    padding: 0,
});

const BrandingListItem = styled('li')(({ theme }) => ({
    marginBottom: '0.5rem',
    fontSize: '20px',
    fontWeight:400,
    fontFamily:'blinker',
    color: '#717389',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    
  }));

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: '#27CCD2',
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      fontSize: '16px',
      
    },
  }));

const SectionFour = () => {
    const tagManagerArgs = {
        gtmId: 'Tag-section4',
        dataLayer: {
            userId: '001',
            userProject: 'section4'
        }
      }
      TagManager.initialize(tagManagerArgs)
    return (
        <Box style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <BrandingContainer>
                <Grid sx={{
          flexDirection:{lg:'row', xs:'column-reverse'}
        }} container spacing={4} alignItems="center">
                    <Grid item xs={6} md={6}>
                        <Typography variant="h3" component="h2" gutterBottom sx={{ fontFamily: "Blinker", fontWeight:800, fontSize: { xs: '28px', md: '40px' } }}>
                          Digital Performance <br/> Optimization Strategies
                        </Typography>
                        <BrandingList>
                        <HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Crafting and sharing valuable content to engage your audience, build trust, and drive profitable customer actions.
  </Typography>
}>
  <BrandingListItem>Content Marketing</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Running targeted ads where you only pay when someone clicks, effectively driving quality traffic to your website.
  </Typography>
}>
  <BrandingListItem>PPC Advertising</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Building your brand's presence and connecting with your audience through organic posts on popular social media platforms.
  </Typography>
}>
  <BrandingListItem>Social Media Marketing</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Using visually engaging banner ads across websites and apps to increase your brand’s visibility and attract potential customers.
  </Typography>
}>
  <BrandingListItem>Display Advertising</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Leveraging paid ads on social media to reach specific audiences and achieve your marketing goals, from increasing traffic to driving sales.
  </Typography>
}>
  <BrandingListItem>Social Media Advertising</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Enhancing your website's performance to boost the percentage of visitors who take desired actions, like making a purchase or signing up.
  </Typography>
}>
  <BrandingListItem>Conversion Rate Optimization</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Implementing strategies to attract and capture the interest of potential customers, turning them into valuable leads for your business.
  </Typography>
}>
  <BrandingListItem>Lead Generation</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Sending personalized, targeted emails to engage your audience, promote your offerings, and build lasting customer relationships.
  </Typography>
}>
  <BrandingListItem>Email Marketing</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Analyzing data from your digital channels to measure performance, gain insights, and guide your marketing strategy.
  </Typography>
}>
  <BrandingListItem>Analytics and Reporting</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Creating compelling videos to promote your brand, connect with your audience, and enhance your online presence.
  </Typography>
}>
  <BrandingListItem>Video Marketing</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Improving the design and user experience of your website or app to make it more intuitive, user-friendly, and conversion-focused.
  </Typography>
}>
  <BrandingListItem>UX/UI Optimization</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Re-engaging visitors who’ve shown interest in your brand by delivering targeted ads that encourage them to return and convert.
  </Typography>
}>
  <BrandingListItem>Re-targeting Campaigns</BrandingListItem>
</HtmlTooltip>
<br/><br/>

                            <ContactUsButton></ContactUsButton>
                        </BrandingList>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <BrandingImage src="../sectionfour.png" alt="Digital strategy" />
                        {/* <BrandingBack src="halfCircle.png" style={{ position: "absolute", right: 0, top: "315vh" }} sx={{
                          display:{xs:'none' , lg:'inline'}
                        }} alt="Decoration" /> */}
                    </Grid>
                </Grid>
            </BrandingContainer>
        </Box>
    );
};

export default SectionFour;
