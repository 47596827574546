// src/FormComponent.js
import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import axios from 'axios';
import CustomButton from './CustomButton';
import HCaptcha from 'react-hcaptcha';
import OkDialog from './okDialog';

function ContactForm() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState('');
  const [verified , setVerified] = useState(false);
  
  const [open,setOpen] = useState(false)
  const handleVerificationSuccess = (token) => {
    setToken(token);
    setVerified(true);
    console.log("ok")
  };
  const handleNameChange = (e) => {
    const input = e.target.value;
    const onlyLetters = input.replace(/[^a-zA-Z\s]/g, '');
    setName(onlyLetters);
  };
const closeModal = () => {
  setOpen(false)
  setTitle('')
setDescription('')
setEmail('')
setPhone('')
setName('')
}
const apiUrl = process.env.REACT_APP_API_URL;
  const handleSubmit = async () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[1-9]\d{9,11}$/;

    if (!name) newErrors.name = 'Name is required';
    
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(email)) {
      newErrors.email = 'Invalid email format';
    }

    if (!title) newErrors.title = 'Title is required';
    if (!description) newErrors.description = 'Description is required';
    if (phone && !phoneRegex.test(phone)) {
      newErrors.phone = 'Phone number must be between 10 to 12 digits';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    

  
    setLoading(true);
    try {
      setErrors({})
      const response = await axios.post(apiUrl+'/guest', {
        title,
        content: description,
        phone,
        email,
        name,
      });
      setOpen(true)
      setMessage(response.data.code);
    } catch (error) {
      alert('Error submitting form');
    }
    setLoading(false);
  };

  return (
    <form style={{ textAlign: 'center' }}>
      <TextField
        label="Name"
        value={name}
        onChange={handleNameChange}
        fullWidth
        margin="normal"
        required
        error={!!errors.name}
        helperText={errors.name}
      />
      <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.email}
        helperText={errors.email}
      />
      <TextField
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.title}
        helperText={errors.title}
      />
      <TextField
        multiline
        rows={4}
        maxRows={7}
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.description}
        helperText={errors.description}
      />
      <TextField
        label="Phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        fullWidth
        margin="normal"
        error={!!errors.phone}
        helperText={errors.phone}
      />
      <HCaptcha
          sitekey="1c9e86fa-1a1a-49a2-ac1a-bd7a740ae79a"
          onVerify={handleVerificationSuccess}
        />
      <CustomButton
        disabled={loading || !verified}
        variant="contained"
        color="primary"
        text={"Submit"}
        clicked={handleSubmit}
      />
      {message && (
        <Typography
          sx={{ color: 'ButtonText', py: 2 }}
          variant="body1"
          color="textSecondary"
          style={{ marginTop: '1rem', fontFamily: 'blinker', fontSize: { md: '24px', xs: '16px' } }}
        >
          Status Code: {message}
        </Typography>
      )}
      <OkDialog OpenModal={open} clicked={closeModal} />
    </form>
  );
}

export default ContactForm;
