import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';

const CountdownTimer = ({ targetDate }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft() {
        const currentDate = new Date().toLocaleString('en-US', { timeZone: 'America/Toronto' });
        const difference = +new Date(targetDate) - +new Date(currentDate);
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    }

    const timerKeys = Object.keys(timeLeft);

    return (
<Grid container sx={{
    alignItems:'center',
    display:'flex',
    flexDirection:'column',
}}>
        <Box
            sx={{
                width: {md:'831px' , sm : '412px'},
                height: {md:'329px' , sm : '658px'},
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                borderRadius: '20px',
                border: '4px solid #bbf8e7'
            }}>
            <Box
                sx={{
                    width: {md:'824px' , sm : '412px'},
                    height: {md:'322px' , sm : '644px'},
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    // background: 'linear-gradient(135deg, #caeaf9 60%, #78c2b2 100%)',
                    backgroundColor: 'transparent',
                    borderRadius: '20px',
                    border: '4px solid #fff'
                }}>
                <Box
                    sx={{
                        width: {md:'824px' , sm : '412px'},
                        height: {md:'317px' , sm : '640px'},
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        // background: 'linear-gradient(135deg, #caeaf9 60%, #78c2b2 100%)',
                        background: 'rgba(229, 242, 253,0.5)',
                        borderRadius: '20px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        backdropFilter: 'blur(100px)',
                        border: '2px solid #bbf8e7'
                    }}
                >
                    <Grid  container  sx={{
                            ml:{md:0,xs:'40px'}
                          }} >
                    {timerKeys.length ? (
                        timerKeys.map((interval, index) => (
                          <>  
                          <Grid item md={3} xs={6}>
                          <Box
                                sx={{
                                    width: {md:'170px', xs: '110px'},
                                    height:  {md:'170px', xs: '110px'},
                                    display: 'flex',

                                    alignItems: 'center',
                                    // background: 'linear-gradient(135deg, #caeaf9 60%, #78c2b2 100%)',
                                    backgroundColor: 'transparent',
                                    borderRadius: '40px',
                                    border: '3px solid #41f6ba',
                                    boxShadow:'0px 0px 20px  #41f6ba'
                                }}>
                                    
                                <Box
                                    sx={{
                                        width: {md:'166px', xs: '105px'},
                                    height:  {md:'163px', xs: '105px'},
                                        borderRadius: '40px',
                                        display: 'flex',

                                    alignItems: 'center',
                                        // border:'4px solid transparent',
                                        flexDirection: 'column',
                                        
                                        backgroundImage: ` conic-gradient(white ${timeLeft[interval] /60 * 100}%, black 10%)`,
                                        backgroundOrigin:'border-box',
                                        backgroundClip:'content-box, border-box',
                                        
                                    }}>
                                        
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            
                                            justifyContent: 'flex-start',
                                            background: 'radial-gradient(#c6f8ec 60%, #92e9e8 100%)',
                                            borderRadius: '40px',
                                            padding: '10px 20px',
                                            mt:'4px',
                                            width: {md:'155px', xs: '99px'},
                                    height:  {md:'155px', xs: '99px'},
                                            backdropFilter: 'blur(100px)',
                                            border: '4px solid #41f6ba',
                                        }}
                                    >
                                        <Typography
                                            variant="h2"
                                            sx={{
                                                color: '#182233',
                                                fontSize: {md: '65px',xs:'38px'},
                                                padding:{lg:'20px',xs:'0'},
                                                fontWeight: 'bold',
                                                fontFamily: 'Digital Numbers'
                                            }}
                                        >
                                            {timeLeft[interval]}
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                color: '#182233',
                                                fontSize: {md:'30px', xs:'17px'},
                                                textTransform: 'uppercase',
                                                marginTop: '5px',
                                                padding:{lg:'40px',xs:0},
                                                fontFamily: 'blinker'
                                            }}
                                        >
                                            {interval}
                                        </Typography>
                                    </Box>
                                    
                                </Box>
                            </Box>
                            
                            </Grid>
                            </>
                        ))
                    ) : (
                        <Typography variant="h4" sx={{ color: '#ffffff' }}>
                            Time's up!
                        </Typography>
                    )}
                    </Grid>
                </Box>
            </Box>
        </Box>
        </Grid>
    );
};

export default CountdownTimer;
