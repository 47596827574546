import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/system"
import seo from "./SVG/SEO.svg"
const ServiceComponent = ({backColor,title,description,image}) => {

    const Image = styled('img')({

    });
    return (
        <Box sx={{

            width: {md: '360px', xs: '319px' },
            height: {md: '211px', xs: '282px' },
            backgroundColor: backColor,
            borderRadius: '20px',
            '&:hover': {
                boxShadow: `0px 0px 20px ${backColor}`
            }
        }}>
            <Grid container sx={{
                py: '39px',
                px: '33px'
            }}>
                <Grid lg={4}></Grid>
                <Grid lg={4}></Grid>
                <Grid lg={4}><Image src={image}></Image></Grid>
                <Grid item lg={12}>
                    <Typography sx={{
                        fontSize: '22px',
                        fontFamily: 'blinker',
                        fontWeight: '600',
                        color:'#000'
                    }}>{title}</Typography>

                    <Typography sx={{
                        fontSize: '16px',
                        fontFamily: 'blinker',
                        fontWeight: '400',
                        color:'#fff'
                    }}>{description}
</Typography>
                </Grid>
            </Grid>
        </Box>
    )


}



export default ServiceComponent