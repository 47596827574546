import React from 'react';
import { Typography, Container, Grid, Box } from '@mui/material';
import { FullpageSection } from '@ap.cx/react-fullpage';
import greenSvg from '../SVG/greenBack.svg'
import ChatBot from '../chatbot';
import { Chat } from '@mui/icons-material';
import {styled} from "@mui/system"
// import greenBack from '../SVG/greenBack';
const Image = styled('img')({

});
const MainSection = () => {
    return (
        <>
        <Grid container height={'100%'} sx={{
            display:'flex',
            flexDirection:{md:'row',xs:'column',sm:'column'},
            alignItems:{xs:'center',md:'inherit',sm:'center'},
            alignContent:{xs:'center',md:'inherit',sm:'center'},
            justifyContent:{xs:'flex-end',md:'inherit',sm:'flex-end'}
            ,flexWrap:'nowrap'
        }}>
            <Grid item lg={1} xs={0} ></Grid>
            <Grid lg={3} xs={12} sx={{
                display:'flex',
                flexDirection:{md:'row',xs : 'column',sm:'column'},
                alignItems:'center',
                
            }}>

        
        {/* <FullpageSection style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}> */}
        {/* <Box id="heroSection" style={{ height: '100vh', display: 'flex', justifyContent: 'center', ali gnItems: 'center', flexDirection: 'column' }}> */}
                <Grid container justifyContent="center" alignItems="center" sx={{  textAlign: {md:'left',xs:'center'}}}>
                    <Grid item xs={12} lg={11}>
                        <Typography variant="h1" component="h1" sx={{ fontFamily: 'Blinker', fontWeight:400, fontSize: { xs: '50px', md: '86px' }, lineHeight:{md:'76px',xs:'47px'},mb:4}}>
                        we've got<br /> your back
                                                </Typography>
                        <Typography variant="h4" component="h2" sx={{ textAlign:{xs:'justify',md:'left'},fontFamily: 'Blinker',color : '#7A7D9C', fontWeight:400, fontSize: { xs: '15px', md: '27px' },width:{lg:'377px',xs:'224px'}  }}>
                        We believe many start-ups fall short of their full potential due to inadequate marketing strategies. At Beverly, we empower businesses to elevate their brand to the world stage, where they can be seen, heard, and thrive. Ready to shine?
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid lg={8} sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:"center" ,
                justifyContent:{md:'flex-end',xs:'flex-end'},
                justifyItems:{md:'flex-end',xs:'center'},
                mb:10
                
            }}>
            <Box sx={{ position: 'relative', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Image
    src="greenBack.svg"
    alt="BackTeam"
    sx={{
      width: { lg: '1020px', xs: '786px' },
      height: { lg: '746px', xs: '658px' },
      position: 'absolute',
      zIndex: -2,
      mt:-40,
      ml:{md:10,xs:40}
      
    }}
  />
  <Image
    src="landing.png"
    alt="Team"
    sx={{
      width: { lg: '650.99px', xs: '450px',md:'500px' },
      height: { xs: '350px', lg: '500px', md:'400px' },
      position: 'relative',
      ml:{md:0,xs:4},
      zIndex: -1,
    }}
  />
                 
                 </Box>
                 </Grid>
           
        {/* </FullpageSection> */}
        {/* <ChatBot></ChatBot> */}
        {/* </Box> */}
        </Grid>
        </>
    );
};

export default MainSection;
