import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import ContactForm from './ContactForm';
import {styled} from '@mui/system'
import OkDialog from './okDialog'

function Contact() {
  const Text = styled(Typography)(({ theme }) => ({
    fontFamily: "blinker",
    fontWeight: 400,
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px",
    },
  }));
  
  return (
    <Box container sx={{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      width:'100%',
      textAlign:'center',
      mb:2
    }}>
    <Grid container sx={{
      mt:5,
      width:'100%',
      display: 'flex',
      height: '100vh',
      alignItems: 'center',
      textAlign: 'center',
      backgroundImage: 'url(contactbg.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: {xs: 'bottom 0px right 44%' , md:'bottom 0px right 54%'},
      backgroundSize: '95vh',
      alignContent: 'stretch',
      
    }}>
      <Grid item xs={12} sx={{ }}>
        <Typography sx={{
          fontSize: { xs: '40px', md: '86px' },

          fontFamily: "Blinker",
          fontWeight:'800',// Responsive font size
          lineHeight:'90px'
        }}>
          Talk to us about your <br /> brand ambitions
        </Typography>
        <Typography sx={{
          fontFamily: 'Blinker, sans-serif',
          fontSize: { xs: '16px', md: '24px' },
          px:{md:'20px' , xs : '10px'} // Responsive font size
        }}>
          We bring a diversity of talent, experience, and cultural perspective to all of our work.
        </Typography>
      </Grid>

      <Grid item xs={12} md={4} sx={{ marginBottom: -2 ,marginTop: '-150px' }}>
        <Typography sx={{
          fontFamily: 'Blinker',
          fontSize: { xs: '24px', md: '48px' }, // Responsive font size
          // fontVariant: 'petite-caps',
        }}>
          Your <strong>S</strong>uccess is<br /> Our <strong>P</strong>riority
        </Typography>
      </Grid>

      <Grid item xs={12} md={4} sx={{ marginTop: '-150px' }}>
        <Typography sx={{
          fontFamily: 'Blinker, sans-serif',
          fontSize: { xs: '16px', md: '24px' }, // Responsive font size
        }}>
          Say hello<br />
          <a
                    style={{
                      display: "inline",
                      fontFamily: "blinker",
                      fontWeight: "800",
                      textDecoration:'none',
                      color:'#000'
                    }}
                    href="mailto:info@beverly.digital"
                  >
                    <Text
                      sx={{
                        display: "inline",
                      }}
                    >
                      E-mail: info@beverly.digital
                    </Text>
                  </a>
                  <br />
          <a style={{
            textDecoration:'none',
            color:'#000'
          }} href='tel:+16475599797'>+1647-559-9797</a>
        </Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography sx={{
          marginTop: '-120px' ,
          fontFamily: 'Blinker, sans-serif',
          fontSize: { xs: '20px', md: '26px' }, // Responsive font size
          fontWeight: 'regular',
          lineHeight:'60px'
        }}>
         <span style={{marginLeft:'-60px'}}> Fuel Your Ambitions</span><br />
         <span>Unleash Your Potential</span><br />
         <span style={{marginLeft:'-30px'}}>Transform Your Future</span>
        </Typography>
        
      </Grid>
      
    </Grid>
    <Grid xs={12} md={12} lg={5} sx={{

      textAlign:'center',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      mb:30,
      mt:30,
      boxShadow:'0px 0px 0px #DADDE4',
      borderRadius:12
    }    }>
    <Typography sx={{
          
          fontFamily: 'Blinker, sans-serif',
          fontSize: { xs: '24px', md: '48px' }, // Responsive font size
          fontWeight: 'regular',
        }}> Contact Us</Typography>
       <Grid sx={{
        mb:2,
       }}  container xs={11} md={11} lg={7}> <ContactForm /></Grid>

    </Grid>
    
    </Box>
  );
}

export default Contact;
