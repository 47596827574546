import React from "react";
import { Box,  Grid,  Typography} from "@mui/material"
import {styled} from "@mui/system"
import banner from './assets/banner.png'
import discover from './assets/Discover.png'
import define from './assets/Define.png'
import ideate from './assets/Ideate.png'
import design from './assets/Design.png'
import section from './assets/section3.png'
import user1 from './assets/User1.png'
import user2 from './assets/User2.png'
import user3 from './assets/User3.png'
import mobile from './assets/mobile.png'
import c1 from './assets/color1.png'
import c2 from './assets/color2.png'
import c3 from './assets/color3.png'
import c4 from './assets/color4.png'
import c5 from './assets/color5.png'
import thanks from './assets/thanks.png'
import './style.css'



const Image = styled('img')(({ theme })=> ({
  
}));


const ColorPalet = styled('img')(({ theme })=> ({
  width:'40px',
  [theme.breakpoints.down('sm')]: {
    width:'19px',
  },
}));
const Dentist = () => {
    return (
      <>
        <Box
          sx={{
            overflow:'hidden',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              item
              lg={12}
              xs={12}
              sx={{
                width: "100%",
              }}
            >
              <Image
                src={banner}
                sx={{
                  width: "100%",
                }}
              ></Image>
            </Grid>
            <Grid item lg={12}>
              <Typography
                sx={{
                  mb: 10,
                  fontFamily: "blinker",
                  fontSize: { lg: "48px", xs: "24px" },
                  color: "#282D46",
                }}
              >
                Our Process
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={10}
            sx={{
              display: "flex",
              width: {lg:'70%' , xs:'50%'},
              mb: 20,
            }}
          >
            <Grid item lg={3} md={6} sm={12}>
              <Image
                sx={{
                  width: "100%",
                }}
                src={discover}
              ></Image>
            </Grid>
            <Grid item lg={3} md={6} sm={12}>
              <Image
                sx={{
                  width: "100%",
                }}
                src={define}
              ></Image>
            </Grid>
            <Grid item lg={3} md={6} sm={12}>
              <Image
                sx={{
                  width: "100%",
                }}
                src={ideate}
              ></Image>
            </Grid>
            <Grid item lg={3} md={6} sm={12}>
              <Image
                sx={{
                  width: "100%",
                }}
                src={design}
              ></Image>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              backgroundColor: "#f3eef4",
            }}
          >
            <Grid
              item
              lg={8}
              sx={{
                flexDirection: "column",
                display: "flex",
                pl: { lg: 20, xs: 2 },
                pt: 10,
              }}
            >
              <Grid item lg={12} sx={{}}>
                <Typography
                  sx={{
                    mb: 10,
                    fontFamily: "blinker",
                    color: "#282D46",
                    fontSize: { lg: "48px", xs: "24px" },
                    textAlign:'left',
                    px:5

                  }}
                >
                  Business Challenges
                </Typography>
              </Grid>
              <Grid item lg={12}>
                <Typography
                  sx={{
                    fontSize: { lg: "22px", xs: "16px" },
                    width: { lg: "65%", sm: "100%" },
                    textAlign:'left',
                    
                    px:5,
                    pb:2
                  }}
                >
                  <Typography sx={{
                  fontWeight:'800',
                  fontFamily:'blinker',
                  fontSize:'24px'
                }}>
                Competition:
                </Typography>Toronto has a high density of dental
                  practices, leading to stiff competition. Dentists must find
                  ways to differentiate their services to attract and retain
                  patients.
                </Typography>
              </Grid>
              <Grid item lg={12}>
                <Typography
                  sx={{
                    fontSize: { lg: "22px", xs: "16px" },
                    width: { lg: "700px", sm: "100%" },
                    textAlign:'left',
                    
                    
                    px:5,
                    pb:2
                  }}
                >
                  <Typography sx={{
                  fontWeight:'800',
                  fontFamily:'blinker',
                  fontSize:'24px'
                }}>
                Rising Costs:
                </Typography>Operational costs, including rent, staff
                  salaries, equipment, and supplies, are continually increasing.
                  Managing these costs while maintaining profitability is a
                  significant challenge.
                </Typography>
              </Grid>
              <Grid item lg={12}>
                
                <Typography
                  sx={{
                    fontSize: { lg: "22px", xs: "16px" },
                    width: { lg: "65%", sm: "100%" },
                    textAlign:'left',
                    px:5,
                    
                  }}
                >
                  <Typography sx={{
                  fontWeight:'800',
                  fontFamily:'blinker',
                  fontSize:'24px'
                }}>
                Marketing and Online Presence:
                </Typography>
                  Effective marketing,
                  including maintaining a robust online presence, managing
                  social media, and engaging in SEO practices, is essential to
                  attract new patients. This requires both time and expertise.
                </Typography>
              </Grid>
            </Grid>
            <Grid item lg={4} sx={{
              mt:10
            }}>
              <Image width={"100%"} src={section}></Image>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "500px",
            }}
          >
            <Grid
              item
              lg={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "500px",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "blinker",
                  fontSize: { lg: "48px", xs: "24px" },
                  color: "#282D46",
                }}
              >
                User Needs
              </Typography>
              <Image
                sx={{
                  display: "inline",
                  position: "absolute",
                  left: 0,
                  top: { lg: "10%", sm: "0", xs: "0" },
                  width: { lg: "350px", md: "200px", sm: "200px", xs: "200px" },
                }}
                src={user1}
              ></Image>
              <Image
                sx={{
                  display: "inline",
                  position: "absolute",
                  right: {
                    lg: "0px",
                    md: "-100px",
                    sm: "-100px",
                    xs: "-150px",
                  },
                  top: "70%",

                  width: { lg: "414px", md: "300px", sm: "300px", xs: "300px" },
                }}
                src={user2}
              ></Image>
              <Image
                sx={{
                  display: "inline",
                  position: "absolute",
                  right: {
                    lg: "0px",
                    md: "-100px",
                    sm: "-100px",
                    xs: "-100px",
                  },
                  top: "-30%",

                  width: { lg: "300px", md: "300px", sm: "300px", xs: "300px" },
                }}
                src={user3}
              ></Image>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              backgroundColor: "#fff",
              height: "100%",
              pl: { lg: 20, xs: 2 },
              pt: 10,
              mb:2
            }}
          >
            <Grid item lg={6} >
              <Typography
                sx={{
                  fontSize: { lg: "22px", xs: "16px" },
                  textAlign:'left',
                    px:5,

                }}
              >
                <Typography sx={{
                  fontWeight:'800',
                  fontFamily:'blinker',
                  fontSize:'24px'
                }}>
                A - People at Risk (Immediate Needs):
                </Typography>This group includes
                individuals facing acute dental problems such as severe pain,
                infection, tooth fractures, or bleeding. Their need for dental
                services is urgent and critical, and they usually visit the
                dentist without delay.
              </Typography>
            </Grid>
            <Grid item lg={10}>
              <Typography
                sx={{
                  fontSize: { lg: "22px", xs: "16px" },
                  textAlign:'left',
                    px:5
                }}
              >
                <Typography sx={{
                  fontWeight:'800',
                  fontFamily:'blinker',
                  fontSize:'24px'
                }}>
                B - People Seeking Preventive Care:
                </Typography> This category includes
                those who prioritize their oral health and regularly visit the
                dentist for check-ups and cleanings. These visits are typically
                planned and preventive in nature.
              </Typography>
            </Grid>
            <Grid item lg={8}>
              <Typography
                className="just"
                sx={{
                  fontSize: { lg: "22px", xs: "16px" },
                  textAlign:'left',
                  
                    px:5
                }}
              >
                <Typography sx={{
                  fontWeight:'800',
                  fontFamily:'blinker',
                  fontSize:'24px'
                }}>
                C - People Acting on Recommendations:
                </Typography> Positive recommendations
                from general physicians, friends, or family can strongly
                motivate individuals to visit the dentist. These individuals are
                often influenced by the experiences and opinions of others.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              // background:''
              alignItems:'center',
              
            }}
          >
            <Grid item lg={7} xs={4}>
              <Grid
                container
                sx={{
                  
                  display: "flex",
                  height: "50vh",
                  flexDirection: "column",
                  alignItems: { lg: "flex-start", xs: "center" },
                  flexWrap: "nowrap",
                  // justifyContent:'center',
                  ml: { lg: 20, xs: 4 },
                  mt:2
                }}
              >
                <Grid item lg={12}>
                  <Typography
                    sx={{
                      fontFamily: "blinker",
                      fontSize: { lg: "48px", xs: "20px" },
                      color: "#282D46",
                      fontWeight:'600',
                      textAlign:'left'
                    }}
                  >
                    Instagram post
                  </Typography>
                  <Typography
                    sx={{
                      mt:{md:30,xs:0},
                      fontSize: "24px",
                      color: "#282D46",
                    }}
                  >
                    color pallet
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: { lg: "row", xs: "row" },
                    alignItems: "center",

                  }}
                  lg={5}
                >
                  <Grid item lg={2} xs={12}>
                    <ColorPalet src={c1} />
                  </Grid>
                  <Grid item lg={2} xs={12}>
                    <ColorPalet src={c2} />
                  </Grid>
                  <Grid item lg={2} xs={12}>
                    <ColorPalet src={c3} />
                  </Grid>
                  <Grid item lg={2} xs={12}>
                    <ColorPalet src={c4} />
                  </Grid>
                  <Grid item lg={2} xs={12}>
                    <ColorPalet src={c5} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={5} xs={8}>
              <Image
                src={mobile}
                sx={{
                  ml: { lg: 0 },
                  width: {xs:"384px",md : '616px'},
                }}
              ></Image>
            </Grid>
            
          </Grid>
          <Grid container sx={{
              backgroundColor:'white',
              mt:4
            }}>
              <Grid lg={12}>
                <Image sx={{
                  width:'100% !important'
                }} src={thanks} />
              </Grid>
            </Grid>
        </Box>
      </>
    );
}
export default Dentist