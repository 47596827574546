import { Box, Container, Grid, Tooltip, Typography, tooltipClasses } from '@mui/material';

import ContactUsButton from '../ContactUsButton';
import { FullpageSection } from '@ap.cx/react-fullpage';
import React from 'react';
import { styled } from '@mui/system';

const BrandingContainer = styled(Container)(({ theme }) => ({
    textAlign: 'left',
}));

const BrandingImage = styled('img')({
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    zIndex: '2',
});

// const BrandingBack = styled('img')({
//   width: '100%',
//   maxWidth: '20%',
//   height: 'auto',
//   zIndex: '2',
// });

const BrandingList = styled('ul')({
    listStyle: 'none',
    padding: 0,
});

const BrandingListItem = styled('li')(({ theme }) => ({
    marginBottom: '0.5rem',
    fontSize: '20px',
    fontWeight:400,
    fontFamily:'blinker',
    color: '#717389',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    
  }));
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: '#27CCD2',
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      fontSize: '16px',
      
    },
  }));
const SectionFive = () => {
    return (
        <Box style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" , marginBottom : 100}}>
            <BrandingContainer>
                <Grid container spacing={4} sx={{
          flexDirection:{lg:'row', xs:'column-reverse'},
          textAlign:'left'
        }} alignItems="center">
                    <Grid item xs={6} md={6}>
                        <Typography variant="h3" component="h2" gutterBottom sx={{ fontFamily: "Blinker", fontWeight:800, fontSize: { xs: '28px', md: '40px' } }}>
                        Search Engine <br/> Optimization (SEO)
                        </Typography>
                        <BrandingList>
                        <HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Identifying the most relevant and valuable search terms to target for increasing visibility and traffic.
  </Typography>
}>
  <BrandingListItem>Keyword Research</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Enhancing website content and HTML elements to improve relevance and rankings in search engine results.
  </Typography>
}>
  <BrandingListItem>On-Page Optimization</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Optimizing the back-end structure and performance of your website to ensure search engines can crawl and index it effectively.
  </Typography>
}>
  <BrandingListItem>Technical SEO</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Refining and updating website content to align with targeted keywords and improve search engine rankings.
  </Typography>
}>
  <BrandingListItem>Content Optimization</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Acquiring high-quality back-links from reputable websites to boost your site’s authority and search engine rankings.
  </Typography>
}>
  <BrandingListItem>Link Building</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Optimizing your online presence to attract more business from relevant local searches in your area.
  </Typography>
}>
  <BrandingListItem>Local SEO</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Ensuring your website is mobile-friendly and optimized for search engines to improve visibility on mobile devices.
  </Typography>
}>
  <BrandingListItem>Mobile SEO</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Conducting comprehensive reviews of your website’s technical aspects to identify and fix SEO issues.
  </Typography>
}>
  <BrandingListItem>Technical Audits</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Monitoring and analyzing SEO performance data to track progress, measure success, and refine strategies.
  </Typography>
}>
  <BrandingListItem>SEO Analytics</BrandingListItem>
</HtmlTooltip>
<br/><br/>

                            <ContactUsButton></ContactUsButton>
                        </BrandingList>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <BrandingImage src="../sectionfive.png" alt="Performance marketing"  sx={{
                        }}/>
                        {/* <BrandingBack src="halfCircle.png" style={{ position: "absolute", right: 0, top: "420vh"}} sx={{
                          display:{xs:'none', lg:'block'}
                        }} alt="Decoration" /> */}
                    </Grid>
                </Grid>
            </BrandingContainer>
        </Box>
    );
};

export default SectionFive;
