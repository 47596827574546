import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";

const AboutSection = () => {
  const responsive = {
        
    desktop: {
      breakpoint: { max: 3000, min:0 },
      items: 1
    },

  }
    const Texts = [" We only engage if we can add value: If a company already has a solid marketing strategy or has reached its full potential, we won't engage. We prioritize impact over opportunity."
        , "We operate with fairness and integrity: Our business practices are built on ethical foundations, prioritizing fairness to all stakeholders and meeting our high moral standards – beyond what's legally required."
        , " We deliver exceptional value: Our products and services are designed to be beneficial, useful, effective, and exceed client expectations."]
    return (<Container >
        <Typography variant="h2" gutterBottom sx={{
          fontFamily: "Blinker",
          fontWeight: '400',
          fontSize: { xs: '20px', md: '30px', lg: '40px' },
          mt: 2,
          // Responsive font size
          lineHeight: {md:'40px',xs:'10px'}
        }}>
          Our Story
        </Typography>
        <Typography variant="body1" sx={{
fontFamily: "Blinker",
fontWeight: '400',
fontSize: { xs: '15px', md: '24px' },
width: { xs: '100%', md: '100%' }, // Responsive width
lineHeight: {md:'36px',xs:'20px'},
mb: 4
}}>
<strong>Beverly</strong> was founded by three friends from diverse backgrounds united in our passion for digital marketing. Prior to starting Beverly, we learned first-hand, based on our <strong>50 years of combined business experience</strong>, that building a business that offers an exceptional product or service isn't enough to succeed. You must also effectively communicate what you offer to your potential clients. This insight led us to establish Beverly with a simple yet ambitious mission: <strong>“to help start-ups reach their full potential."</strong>
</Typography>
<Typography variant="h5" gutterBottom sx={{
fontFamily: "Blinker",
fontWeight: '400',
fontSize: { xs: '15px', md: '24px' },
width: { xs: '100%', md: '100%' }, // Responsive width
lineHeight: {md:'15px',xs:'15px'},
}}>
Since our inception, we have been guided by <strong>three core principles</strong> that drive us to fulfill our mission:
</Typography>
<Grid  container spacing={4} sx={{
            mt:2,
            maxWidth: '1200px',
            width:'100%',
            display: 'flex',
            flexDirection: {md:'row',xs:'column'},
            alignContent: 'center',
            alignItems:'center',
            justifyContent:'center'
          }}>
            {Texts.map(x => (
              <Grid item lg={4} md={6} xs={0} sx={{
                display:{md:'inline' , xs:'none'}
              }}>
                <Box sx={{
                  width: {md:'343px', xs:'260px'}
                  , height: {md:'350px',xs:'270px'},
                  boxShadow: '0 0 30px #e2e4eb',
                  backgroundColor: '#fff',
                  borderRadius: '30px',
                  alignContent: 'center'
                }}>
                  <Typography sx={{
                    p: 4,
                    fontSize: {md:'20px', xs:'12px'},
                    fontFamily:'blinker',
                    lineHeight:'38px',
                    fontWeight:'400'

                  }}>{x}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              mt:5,
              width: { md: "0", xs: "100%" },
            }}
          >
            <Carousel
              infinite={true}
              className="sliderClass"
              responsive={responsive}
              swipeable
              slidesToSlide={1}
              transitionDuration={20}
              autoPlaySpeed={500}
            >
              {Texts.map((x) => (
                <div style={{
                    marginLeft:'15%',
                    // marginRight:'7%'
                    
                }}>
                
                <Box sx={{
                  width: {md:'343px', xs:'260px'}
                  , height: {md:'350px',xs:'270px'},
                  boxShadow: '0 0 30px #e2e4eb',
                  backgroundColor: '#fff',
                  borderRadius: '30px',
                  alignContent: 'center'
                }}>
                  <Typography sx={{
                    p: 4,
                    fontSize: {md:'20px', xs:'12px'},
                    fontFamily:'blinker',
                    lineHeight:'38px',
                    fontWeight:'400'

                  }}>{x}</Typography>
                </Box>
                </div>
              ))}
            </Carousel>
          </Box>
      </Container>)
}

export default AboutSection;