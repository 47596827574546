import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Card, CardContent, Typography } from '@mui/material';

const WorksSingle = () => {
  const location = useLocation();
  const { imageUrl } = location.state || {};

  if (!imageUrl) {
    return <Typography>No image provided.</Typography>;
  }

  return (
    <Box sx={{}}>
      <Card>
        <CardContent>
          <img src={imageUrl} alt="Work Image" style={{ width: '100%', height: 'auto' }} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default WorksSingle;