import { createTheme } from '@mui/material/styles';

const theme = createTheme ({
  palette: {
    primary: {
        main: '#10f9b1',
        
      },
  },
});

export default theme;