import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Box, Button, Typography, Grid, TextField, Stepper, StepLabel, Step, FormHelperText } from '@mui/material';
import { styled } from '@mui/system';
import { Link, Navigate, redirectDocument, useParams } from 'react-router-dom';
import SingleQuotePage from './SingleQuotePage';
import ThanksModal from './ThanksModal';
import TagManager from 'react-gtm-module';

import QuoteButton from './QuoteButtons';

const apiUrl = process.env.REACT_APP_API_URL;

const CustomButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(170deg, rgba(82, 239, 189,1) 95%, rgba(255,255,253,1) 100%)',
  borderRadius: '12px',
  color: '#213e46',
  padding: '10px 20px',
  fontFamily: 'blinker',
  fontWeight: '800',
  textTransform: 'none',
  '&:hover': {
    background: 'linear-gradient(90deg, rgba(0,255,191,0.9) 0%, rgba(19,208,253,0.9) 100%)',
  },
}));

const GetQuote = () => {

  const tagManagerArgs = {
    gtmId: 'GTM-WTZKX3NK',
    dataLayer: {
      'quote': 'quote',
    },
    dataLayerName: 'quote',
  };
  TagManager.initialize(tagManagerArgs);

  window.dataLayer.push({
    event: 'quote',
  });

  const { id } = useParams(); // Extract id from the URL
  const [Last, setLast] = useState(false);
  const [buttons, setButtons] = useState([]);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [emailPage, setEmailPage] = useState(false);
  const [email, setEmail] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    axios.get(`${apiUrl}/quotes/39`)
      .then(response => {
        setData(response.data.$values);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleNext = (selectedContent) => {
    if (currentPage < data.length) {
      setCurrentPage(currentPage + 1);
      setSelectedAnswers([...selectedAnswers, selectedContent]);
    }
    if (currentPage === data.length - 2) {
      setLast(true);
    }
    if (Last) {
      handleFinish();
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      setLast(false);
    }
  };

  const handleFinish = () => {
    setEmailPage(true);
  };

  const handleStepClick = (index) => {
    setCurrentPage(index);
    setLast(index === data.length - 1);
  };

  const SendData = () => {
    if (email === '' || emailError !== '') {
      setEmailError('Email Field Required');
      return;
    }
    axios.post(`${apiUrl}/submit-quote`, {
      Email: email,
      children: selectedAnswers,
    }).then(r => setOpenModal(true));
  };

  const redirectFunc = () => {
    window.location.href = '/';
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Regular expression for validating an Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(value)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  return (
    loading ?
      <>
        <Container sx={{
          height: '100%',
          backgroundColor: '#FCFCFC',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          background: 'rgb(255,255,255)',
          backgroundRepeat: 'no-repeat',
          flexDirection: 'column',
          width: '80%',
        }}>
          <Typography>Loading .. </Typography>
        </Container>
      </> : !emailPage ?
        <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid item lg={2} sx={{
            display: {
              lg: 'inline',
              xs: 'none',
            }
          }}>
            <Stepper sx={{ ml: 5, height: '90%' }} activeStep={currentPage} orientation='vertical'>
              {data.map((step, index) => (
                <Step key={step.quotestion} >
                  <StepLabel
                    optional={
                      index === currentPage - 1 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    Step  {index + 1}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Container
            sx={{
              height: '100%',
              minHeight:'100vh',
              backgroundColor: '#FCFCFC',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              background: 'rgb(255,255,255)',
              backgroundRepeat: 'no-repeat',
              flexDirection: 'column',
              width: '80%',
              mb:10
            }}
          >
            <Typography sx={{
              fontFamily: "blinker",
              fontWeight: "600",
            }}> Question : {currentPage + 1} /  {data.length}</Typography>

            <SingleQuotePage onNext={handleNext} pageData={data[currentPage]} />
            <div>
              <CustomButton sx={{ mx: 2 }} onClick={handlePrevious} disabled={currentPage === 0}>Previous</CustomButton>
              <CustomButton sx={{ display: Last ? 'none' : 'none' }} onClick={handleNext} hidden={Last}>Next</CustomButton>
              <CustomButton onClick={handleFinish} sx={{ display: Last ? 'inline' : 'none' }}>Finish</CustomButton>
            </div>
          </Container>
          
        </Grid>
        : <>
          <Container
            sx={{
              minHeight: '70vh',
              backgroundColor: '#FCFCFC',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'rgb(255,255,255)',
              backgroundRepeat: 'no-repeat',
              flexDirection: 'column',
              
            }}
          >
             <Box sx={{
              display: 'flex',flexDirection:'column',alignItems:'center', width: '60%', textAlign: 'center',
              border:'1px solid #999' ,p:10,borderRadius:'20px',
            }}>
            <Box sx={{ display: 'block', width: '100%', textAlign: 'center', mb:5}} >
              <Typography variant="h3" component="h2" fontWeight="400" gutterBottom>
                Enter Your Email
              </Typography>
            </Box>
           
            <TextField
              onChange={handleEmailChange}
              value={email}
              sx={{ mb: 2 }}
              title="Email : "
              placeholder="example@gmail.com"
              required
              error={Boolean(emailError)}
            />
            {emailError && <FormHelperText error>{emailError}</FormHelperText>}
            <QuoteButton onClick={SendData}>Send Mail</QuoteButton>
            </Box>
            <ThanksModal clicked={redirectFunc} openModal={openModal}></ThanksModal>
          </Container>
          
        </>
  );
};

export default GetQuote;
