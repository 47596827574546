import * as React from 'react';
import { useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Lottie from 'react-lottie';
import tick from '../lottie/error.json';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorDialog = ({OpenModal,clicked}) => {
  const [open, setOpen] = React.useState(false);
  
  const handleClose = () => {
    setOpen(false);
    clicked()
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    
    animationData: tick,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
console.log(OpenModal)
  return (
    <React.Fragment>
      
      <Dialog
        open={OpenModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Hey Again!"}</DialogTitle>
        <DialogContent>

          <DialogContentText id="alert-dialog-slide-description">

          <Lottie
              options={defaultOptions}
              width={150}
              height={150}
            ></Lottie>
            It looks like you've already used this code. For any additional requests, please check out our <a href='https://beverly.digital/Technical-Support'>Technical Support</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ErrorDialog 