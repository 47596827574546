import React, { useEffect, useState } from "react";
import SectionOne from "./SectionOne";
import Fullpage, { FullPageSections } from '@ap.cx/react-fullpage';
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import Footer from "../mainFooter";
import SectionFour from "./AboutSection";
import SectionFive from "./SectionFive";
import ChatBot from "../chatbot";
import ReactPageScroller from 'react-page-scroller';
import '../../Library/css/dotsHome.css';
import MainSection from "./MainSection";
import SectionSix from "./sectionsix";
import SectionSeven from "./SectionSeven";
import SectionService from "./SectionService";
import { useMediaQuery, useTheme } from '@mui/material';

const Home = ({ currentPage, setCurrentPage }) => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('md'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const handlePageChange = (number) => {
    setCurrentPage(number);
  };

  return (
    <div>
       
        <>
          <ReactPageScroller pageOnChange={handlePageChange} customPageNumber={currentPage}>
            <MainSection />
            <SectionOne />
            
            <SectionService />
            <SectionThree />
            <SectionFour />
            
            <Footer />
          </ReactPageScroller>
          <div className="pagination-dots">
            {[0, 1, 2, 3, 4, 5].map(number => (
              <div
                key={number}
                className={`dot ${currentPage === number ? 'active' : ''}`}
                onClick={() => handlePageChange(number)}
              ></div>
            ))}
          </div>
        </>
      )
    </div>
  );
};

export default Home;
