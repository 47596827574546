import React from 'react';
import { Grid, Typography, Container, Box } from '@mui/material';
import { styled } from '@mui/system';
import { FullpageSection } from '@ap.cx/react-fullpage';
import ContactUsButton from '../ContactUsButton';

const BrandingContainer = styled(Container)(({ theme }) => ({
    textAlign: 'left',
}));

const BrandingImage = styled('img')({
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    zIndex: '2',
});

const BrandingBack = styled('img')({
  width: '100%',
  maxWidth: '20%',
  height: 'auto',
  zIndex: '2',
});

const BrandingList = styled('ul')({
    listStyle: 'none',
    padding: 0,
});

const BrandingListItem = styled('li')(({ theme }) => ({
    marginBottom: '0.5rem',
    fontSize: '20px',
    fontWeight:400,
    fontFamily:'blinker',
    color: '#717389',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    
  }));

const SectionFive = () => {
    return (
        <Box sx={{ height: {lg:"100vh" , md:'100vh',sm : '95vh', xs:'70vh'}, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <BrandingContainer>
                <Grid container spacing={4} sx={{
          flexDirection:{lg:'row', xs:'column-reverse'},
          textAlign:'left'
        }} alignItems="center">
                    <Grid item xs={6} md={6}>
                        <Typography variant="h3" component="h2" gutterBottom sx={{ fontFamily: "Blinker", fontWeight:800, fontSize: { xs: '28px', md: '40px' } }}>
                            PERFORMANCE<br />
                            MARKETING
                        </Typography>
                        <BrandingList>
                            <BrandingListItem>Growth strategy</BrandingListItem>
                            <BrandingListItem>PPC campaigns</BrandingListItem>
                            <BrandingListItem>Email marketing</BrandingListItem>
                            <BrandingListItem>Social media marketing</BrandingListItem>
                            <BrandingListItem>Launch strategies</BrandingListItem>
                            <BrandingListItem>Conversion-rate optimization</BrandingListItem>
                            <BrandingListItem>Account-based marketing</BrandingListItem>
                            <BrandingListItem>SEO enablement</BrandingListItem>
                            <ContactUsButton></ContactUsButton>
                        </BrandingList>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <BrandingImage src="sectionfive.png" alt="Performance marketing"  sx={{
                        //   ml:{xs:5,md:10}
                        }}/>
                        {/* <BrandingBack src="halfCircle.png" style={{ position: "absolute", right: 0, top: "420vh"}} sx={{
                          display:{xs:'none', lg:'block'}
                        }} alt="Decoration" /> */}
                    </Grid>
                </Grid>
            </BrandingContainer>
        </Box>
    );
};

export default SectionFive;
