import { Box, Container, Grid, Tooltip, Typography } from '@mui/material';

import ContactUsButton from '../ContactUsButton';
import { FullpageSection } from '@ap.cx/react-fullpage';
import { Padding } from '@mui/icons-material';
import React from 'react';
import { styled } from '@mui/system';
import { tooltipClasses } from '@mui/material/Tooltip';

const BrandingContainer = styled(Container)(({ theme }) => ({
  textAlign: 'left',
  padding: theme.spacing(2),
}));

const BrandingImage = styled('img')(({ theme }) => ({
  width: '90%',

  height: 'auto',
  zIndex: '2',
  [theme.breakpoints.down('md')]: {
    maxWidth: '80%',
    marginTop: theme.spacing(4),
  },
}));

const BackImage = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '80%',
  position: 'absolute',
  top: '220vh',
  height: 'auto',
  zIndex: '2',
  [theme.breakpoints.down('md')]: {
    maxWidth: '30%',
    marginTop: theme.spacing(4),
  },
}));

const BrandingList = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
});

const BrandingListItem = styled('li')(({ theme }) => ({
  marginBottom: '0.5rem',
  fontSize: '20px',
  fontWeight: 400,
  fontFamily: 'blinker',
  color: '#717389',
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },

}));
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: '#27CCD2',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    fontSize: '16px',
    
  },
}));
const SectionThree = () => {
  return (
    <Box style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <BrandingContainer>
        <Grid container alignItems="center" sx={{
          flexDirection: { lg: 'row', xs: 'column-reverse' }
        }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" component="h2" gutterBottom sx={{ fontFamily: "Blinker", fontWeight: 800, fontSize: { xs: '28px', md: '40px' }, display: 'flex', }}>
              BRANDING
            </Typography>
            <BrandingList>
            <HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Crafting a comprehensive plan that defines your brand’s goals, positioning, and unique value proposition to guide all branding efforts.
  </Typography>
}>
  <BrandingListItem>Brand Strategy Development</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Creating visual elements like logos, color schemes, and typography that represent your brand’s personality and values.
  </Typography>
}>
  <BrandingListItem>Brand Identity Design</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Developing consistent and compelling messaging that communicates your brand’s core values and resonates with your target audience.
  </Typography>
}>
  <BrandingListItem>Brand Messaging</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Conducting thorough research to understand your market, competition, and customer needs, informing your branding decisions.
  </Typography>
}>
  <BrandingListItem>Market Research and Analysis</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Revamping and redefining your brand’s identity to better align with current goals, market conditions, or audience perceptions.
  </Typography>
}>
  <BrandingListItem>Re-branding</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Crafting a narrative that conveys your brand’s history, mission, and values in a way that connects emotionally with your audience.
  </Typography>
}>
  <BrandingListItem>Brand Storytelling</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Executing targeted campaigns to increase recognition and familiarity with your brand among your target audience.
  </Typography>
}>
  <BrandingListItem>Brand Awareness Campaigns</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Creating and sharing content across social media platforms that reinforces your brand’s identity and engages your audience.
  </Typography>
}>
  <BrandingListItem>Social Media Content</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Producing high-quality videos that tell your brand’s story, showcase products, and build a stronger connection with your audience.
  </Typography>
}>
  <BrandingListItem>Branded Video Production</BrandingListItem>
</HtmlTooltip>

<HtmlTooltip title={
  <Typography sx={{ fontSize: '20px', fontFamily: 'blinker' }}>
    Developing and distributing content that integrates your brand into engaging, relevant, and valuable narratives across various platforms.
  </Typography>
}>
  <BrandingListItem>Branded Content Campaigns</BrandingListItem>
</HtmlTooltip>
<br/><br/>

              <ContactUsButton></ContactUsButton>
            </BrandingList>
          </Grid>
          <Grid item xs={12} md={6}>
            <BrandingImage src="../pc.png" alt="Branding" />
            {/* <BackImage sx={{
              display:{
                xs:'none',
                lg:'inline'
              }
            }} src="halfCircle.png"  alt="Decoration" /> */}
          </Grid>
        </Grid>
      </BrandingContainer>
    </Box>
  );
};

export default SectionThree;
