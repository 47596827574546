import { Box, Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import SectionFive from "../Static/SectionFive";
import SectionThree from "./SectionThree";
import ServiceComponent from "../serviceSectionComponent";
import dentist from '../Works/dentist/assets/dentBanner.png'
import rux from '../Works/rux/assets/ruxBanner.png'
import secOneImage from './pc.png'
import secThreeImage from './sectionfive.png'
import secTowImage from './sectionfour.png'

// import SectionFour from "../Static/SectionFour";











const services = () => {
  const data = [{
    title: 'Keyword Research',
    description: 'Identifying key search terms to boost visibility and traffic!',
    image: './s1.svg',
    backColor: '#76F998',
    link:'/services/3'
},
{
    title: 'Brand Identity Design',
    description: 'Designing logos, color schemes, and typography to reflect your brand identity!',
    image: './s2.svg',
    backColor: '#83E0F5',
    link:'/services/1'
},
{
    title: 'Market Research and Analysis',
    description: 'Researching market, competition, and customer needs to shape branding!',
    image: './s3.svg',
    backColor: '#BBC6F3',
    link:'/services/1'
},
{
    title: 'Content Marketing',
    description: 'Creating valuable content to engage your audience and drive actions!',
    image: './s4.svg',
    backColor: '#30CBEF',
    link:'/services/2'
},
{
    title: 'Social Media Advertising',
    description: 'Using paid social ads to target audiences and achieve marketing goals!',
    image: './s5.svg',
    backColor: '#9AAAEE',
    link:'/services/2'
},
{
    title: 'On-Page Optimization',
    description: 'Optimizing website content and HTML for better search engine rankings!',
    image: './s6.svg',
    backColor: '#1BE4A6',
    link:'/services/3'
},
{
    title: 'UX/UI Optimization',
    description: 'Streamlining design for a user-friendly, conversion-focused experience!',
    image: './s7.svg',
    backColor: '#40FAC1',
    link:'/services/2'
},
{
    title: 'Brand Awareness Campaigns',
    description: 'Running targeted campaigns to boost brand recognition with your audience!',
    image: './s8.svg',
    backColor: '#30CBEF',
    link:'/services/1'
},
{
    title: 'Branded Video Production',
    description: 'Creating high-quality videos to tell your brands story and engage your audience!',
    image: './s9.svg',
    backColor: '#788DE8',
    link:'/services/1'
},
]

    
    return (
<Box
          sx={{
            display: "flex",
            flexDirection:"column",
            height: "100%",
            maxWidth: "100%",
            alignItems:'center',
            alignContent:'center',
            mb:5
          }}
        >
          <Typography sx={{
            fontFamily:'Blinker',
            fontSize:{md:'48px' , xs:'24px'}
            ,fontWeight:'800',
            mb:4
          }}>Our Services</Typography>
          <Grid
            container
            sx={{
              display: { md: "flex", xs: "flex" },
              flexDirection: {lg:"row",md:'row',xs:'column'},
              height: {lg:'100%',md:'100%'},
              maxWidth:'1200px',
              alignItems:'center',
              alignContent:'center',
              justifyContent:'center',
              justifyItems:'center'
            }}
            spacing={5}
            
          >
            {data.map((x) => (
              <Grid sx={{}} item lg={4} md={6} xs={12}>
                <Link style={{
                  textDecoration:'none'
                }} to={x.link}>
                <ServiceComponent
                  title={x.title}
                  description={x.description}
                  image={x.image}
                  backColor={x.backColor}
                ></ServiceComponent>
                </Link>
              </Grid>
            ))}
          </Grid>
          </Box>
    )
}


export default services;