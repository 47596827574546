
import Hamburger from "hamburger-react";
import * as React from 'react';
import {styled} from '@mui/system';
import { useState } from "react";
import Menu from "./hamMenu";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";


const XsMenu = ()=> {
    
    const [isOpen, setOpen] =useState(false);

    const toggleMenu = () => {
      setOpen(!isOpen);
    };

    
    const Image = styled('img')({

    });
    return(
    <>
    <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent:{lg : 'center', xs: 'left'},
                flexDirection:  {lg : 'column', xs : 'row-reverse'},
                alignItems: "center",
                flexWrap:{md : 'nowrap' , lg : 'warp'}
              }}
            >
              <Link to="">
                <Image
                  sx={{
                    position: "relative",
                    zIndex: "100000",
                    width:{md : '166px' , xs : '86px'},
                    height: {md : '43px ' , xs : '30px'}
                  }}
                  onClick={isOpen ? toggleMenu : null}
                  src={isOpen ? "/logoW.png" : "/logo.png"}
                  alt="logo"
                />
              </Link>
            </Box>
   <Hamburger toggled={isOpen} toggle={toggleMenu} />
   <Menu isOpen={isOpen} toggleMenu={toggleMenu}></Menu>
   
            </>
    )
}

export default XsMenu;