// src/App.js
import React from 'react';
import {Container, Typography, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import {styled} from '@mui/system'
import CodeComponent from './Ticket/CodeComponent';
import FormComponent from './Ticket/FormComponent';




const CustomAccard = styled(Accordion)({
      width: '100%',
      margin: '10px auto',
      borderRadius:'40px',
      backgroundColor:'transparent !important',
      border:'1px solid #ddd'
  });
const Ticket = () => {
    return (
        <Container maxWidth="md" sx={{
          mt:15
        }} style={{marginBottom:100}}>
      <Typography  variant="h4" component="h2" sx={{
        fontWeight:'600',
        fontFamily:'blinker',
        
      }} gutterBottom align="left">
        Technical support
      </Typography>
        
        <CustomAccard elevation={0} expanded>
          <AccordionSummary>
            <Typography sx={{
                
                fontFamily:'blinker',
                fontWeight:'600'
            }}>If you need technical support with one of your products, please contact your account manager or enter your information below to generate a new ticket for promot assistance.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormComponent />
          </AccordionDetails>
        </CustomAccard>
        <CustomAccard elevation={0} expanded>
          <AccordionSummary>
            <Typography sx={{
                fontFamily:'blinker',
                fontWeight:'600'
            }}>Check the status of your ticket by entering your reference number below. </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CodeComponent />
          </AccordionDetails>
        </CustomAccard>
    </Container>
      );
}
export default Ticket;