import { Box, FormControlLabel, Radio, RadioGroup, TextField, Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate ,useLocation } from "react-router-dom";
import ErrorDialog from "../ErrorDialog";
const UserData = () => {
    const location = useLocation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [code, setCode] = useState('');
    const [errors, setErrors] = useState({});
    const [subject,setSubject] = useState('Say Hi');
    const router = useNavigate()
    const [open,setOpen] = useState(false)
    const apiUrl = process.env.REACT_APP_API_URL;
    const validateName = (name) => {
        const nameRegex = /^[a-zA-Z\s]+$/;
        return nameRegex.test(name);
    };

    useEffect(() => {
        // Extract query string parameter
        const queryParams = new URLSearchParams(location.search);
        setCode(queryParams.get('code'));
    }, [location.search])

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };


    const clicked = () => {
        setOpen(false)

    }

    const handleSendMessage = () => {
        let valid = true;
        let newErrors = {};

        if (!validateName(name)) {
            newErrors.name = "Name can only contain letters and spaces.";
            valid = false;
        }

        if (!validateEmail(email)) {
            newErrors.email = "Please enter a valid email address.";
            valid = false;
        }
        if(subject === null){
            newErrors.subject = "Please select subject";
            valid = false;
        }

        if (valid) {
            axios.post(apiUrl+'/user-data', {
                name : name,
                email:email,
                code:code,
                description : description,
                subject : subject
            }).then(res => {
                router('/campaign/thanks')
            }).catch(res => {
               setOpen(true) 
                // alert("This Code is used code, please ticket us for new code")
            })
        } else {
            setErrors(newErrors);
        }
    };

    return (
        <Box sx={{
            minHeight: '100vh',
            height: '100%',
            backgroundColor: '#cbeaf8',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Blinker, sans-serif',
            fontSize: '20px',
            fontWeight: 400,
            p: { xs: 2, md: 3, lg: 4 }, // padding adjustments for different screen sizes
        }}>
            <Box sx={{
                width: { xs: '90%', md: '70%', lg: '50%' }, // Adjust the width based on screen size
                height: 'auto',
                px: { xs: 2, md: 6, lg: 10 }, // Adjust padding for different screen sizes
                py: { xs: 2, md: 4, lg: 6 }, // Adjust padding for different screen sizes
                border: '1px solid #000',
                borderRadius: '20px',
                backgroundColor: '#fff',
            }}>
                <RadioGroup required value={subject}
        onChange={e=> setSubject(e.target.value)} row sx={{ fontFamily: 'Blinker, sans-serif', fontSize: '20px', fontWeight: 400 }}>
                    <FormControlLabel  value="Say Hi" control={<Radio />} label="Say Hi" />
                    <FormControlLabel  value="GetQuote" control={<Radio />} label="Get Quote" />
                </RadioGroup>
                
                <TextField
                    label="Name"
                    fullWidth
                    required
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={!!errors.name}
                    helperText={errors.name}
                    sx={{
                        borderRadius: '10px',
                        fontFamily: 'Blinker, sans-serif',
                        fontSize: '20px',
                        fontWeight: 400,
                    }}
                    InputProps={{
                        sx: {
                            borderRadius: '10px',
                            fontFamily: 'Blinker, sans-serif',
                            fontSize: '20px',
                            fontWeight: 400,
                        }
                    }}
                />

                <TextField
                    label="Email"
                    fullWidth
                    required
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email}
                    sx={{
                        borderRadius: '10px',
                        fontFamily: 'Blinker, sans-serif',
                        fontSize: '20px',
                        fontWeight: 400,
                    }}
                    InputProps={{
                        sx: {
                            borderRadius: '10px',
                            fontFamily: 'Blinker, sans-serif',
                            fontSize: '20px',
                            fontWeight: 400,
                        }
                    }}
                />
                <TextField
                
                    label="Code"
                    fullWidth
                    margin="normal"
                    required
                    disabled
                    value={code}
                    
                    onChange={(e) => setCode(e.target.value)}
                    
                    sx={{
                        borderRadius: '10px',
                        fontFamily: 'Blinker, sans-serif',
                        fontSize: '20px',
                        fontWeight: 400,
                        display:'none'
                    }}
                    InputProps={{
                        sx: {
                            borderRadius: '10px',
                            fontFamily: 'Blinker, sans-serif',
                            fontSize: '20px',
                            fontWeight: 400,
                        }
                    }}
                />

                <TextField
                    label="Description"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    sx={{
                        borderRadius: '10px',
                        fontFamily: 'Blinker, sans-serif',
                        fontSize: '20px',
                        fontWeight: 400,
                    }}
                    InputProps={{
                        sx: {
                            borderRadius: '10px',
                            fontFamily: 'Blinker, sans-serif',
                            fontSize: '20px',
                            fontWeight: 400,
                        }
                    }}
                />
                
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSendMessage}
                    sx={{
                        mt: 3,
                        background: 'linear-gradient(to right, #10F9B1, #38CBF9)',
                        color: '#000',
                        borderRadius: '10px',
                        border: '1px solid #000',
                        fontFamily: 'Blinker, sans-serif',
                        fontSize: '20px',
                        fontWeight: 400,
                    }}
                >
                    Send Message
                </Button>
            </Box>
            <ErrorDialog OpenModal={open} clicked={clicked}/>
        </Box>
    );
};

export default UserData;
