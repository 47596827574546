import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import {styled} from "@mui/system"
import { Link } from "react-router-dom";
import QuoteButton from "./QuoteButtons";


const CustomButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(170deg, rgba(82, 239, 189,1) 95%, rgba(255,255,253,1) 100%)',
    borderRadius: '12px',
    color: '#213e46',
    padding: '10px 20px',
    fontFamily:'blinker',
    fontWeight:'800',
    textTransform: 'none',
    fontSize:{md:'24px',xs : '16px'},
    '&:hover': {
      background: 'linear-gradient(90deg, rgba(0,255,191,0.9) 0%, rgba(19,208,253,0.9) 100%)',
    },
  }));



  const SingleQuotePage = ({pageData,onNext})=> {

    const handleButtonClick = (content) => {
        onNext(content);
      };
    return(
        <>
        <Box sx={{
            display:'block',
            width:'100%',
            textAlign:'center',

        }} >
        <Typography variant="h3" component="h2" sx={{
          fontFamily:'blinker',
          fontSize:{md:'48px' , xs : '24px'}
        }} fontWeight="bold" gutterBottom>
          {pageData.quotestion}
        </Typography>
        </Box>
      <Box
        sx={{
          bgcolor: 'white',
          borderRadius: '12px',
          p: 4,
          backgroundColor:'#FCFCFC',
          textAlign: 'center',
          maxWidth: '800px',
          width: '100%',
          
        }}
      >
        
        <Grid container spacing={2} justifyContent="center">
          {pageData.children.$values.map((button,index) => (
            <Grid item xs={12} sm={4} key={index}>
              <QuoteButton
                onClick={() => handleButtonClick(button.content)}
                variant="contained"
                fullWidth
               
              >
                {button.content}
              </QuoteButton>
            </Grid>
          ))}
        </Grid>
      </Box>
      </>
    )
  }
  export default SingleQuotePage;