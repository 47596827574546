import { Style } from "@mui/icons-material";
import { Box, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { redirect, redirectDocument } from "react-router-dom";
import CustomButton from "./CustomButton";



const ThanksModal = ({openModal,clicked}) => {

    
    const handleClose = () => {
        redirectDocument('/')
    }
    return (
    <Modal
    sx={{
        textAlign:'center',
        display:"flex",
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    }}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
            textAlign:'center',
            backgroundColor:'white',
            width:'400px',
            p:'100px',
            borderRadius:'12px'
        }}>
          <Typography sx={{
            fontFamily : 'blinker ',
            fontWeight:'800'
          }} id="modal-modal-title" variant="h2" component="h2">
            Thanks 
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2,fontFamily : 'blinker ',fontSize:20,
            fontWeight:'600' }}>
            Wait For Our call
          </Typography>
          <CustomButton clicked={clicked} text={'Done'}></CustomButton>
        </Box>
      </Modal>)
}

export default ThanksModal