import React from 'react';
import SectoinOne from './rux/sectionOne';
import SectoinTwo from './rux/sectionTwo';
import '../../Library/css/rux.css'
import { Box } from '@mui/material';
const RuxIndex = () => {
  return (
    <Box sx={{
      overflow:'hidden',
    }}>
    <SectoinOne />
    <SectoinTwo />
    </Box>
  );
};

export default RuxIndex;
