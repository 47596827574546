import "react-multi-carousel/lib/styles.css";

import { Box, Grid } from "@mui/material";

import Carousel from 'react-multi-carousel'
import { Link } from "react-router-dom";
import React from "react";
import ServiceComponent from "../serviceSectionComponent";
import { styled } from "@mui/system"

const SectionService = () => {
    const data = [{
        title: 'Keyword Research',
        description: 'Identifying key search terms to boost visibility and traffic!',
        image: './s1.svg',
        backColor: '#76F998',
        link:'/services/3'
    },
    {
        title: 'Brand Identity Design',
        description: 'Designing logos, color schemes, and typography to reflect your brand identity!',
        image: './s2.svg',
        backColor: '#83E0F5',
        link:'/services/1'
    },
    {
        title: 'Market Research and Analysis',
        description: 'Researching market, competition, and customer needs to shape branding!',
        image: './s3.svg',
        backColor: '#BBC6F3',
        link:'/services/1'
    },
    {
        title: 'Content Marketing',
        description: 'Creating valuable content to engage your audience and drive actions!',
        image: './s4.svg',
        backColor: '#30CBEF',
        link:'/services/2'
    },
    {
        title: 'Social Media Advertising',
        description: 'Using paid social ads to target audiences and achieve marketing goals!',
        image: './s5.svg',
        backColor: '#9AAAEE',
        link:'/services/2'
    },
    {
        title: 'On-Page Optimization',
        description: 'Optimizing website content and HTML for better search engine rankings!',
        image: './s6.svg',
        backColor: '#1BE4A6',
        link:'/services/3'
    },
    {
        title: 'UX/UI Optimization',
        description: 'Streamlining design for a user-friendly, conversion-focused experience!',
        image: './s7.svg',
        backColor: '#40FAC1',
        link:'/services/2'
    },
    {
        title: 'Brand Awareness Campaigns',
        description: 'Running targeted campaigns to boost brand recognition with your audience!',
        image: './s8.svg',
        backColor: '#30CBEF',
        link:'/services/1'
    },
    {
        title: 'Branded Video Production',
        description: 'Creating high-quality videos to tell your brands story and engage your audience!',
        image: './s9.svg',
        backColor: '#788DE8',
        link:'/services/1'
    },
    ]

    const responsive = {
        
        desktop: {
          breakpoint: { max: 3000, min:0 },
          items: 1
        },

      }
    return (
      <>
      <Box 
      sx={{
        display:'flex',
        flexDirection:'row',
        
        height:'100vh',
        alignItems:{md:'flex-start',xs:'center'},
        justifyContent:'center',
        mt:"5px"



      }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection:"column",
           
            height: "90%",
            alignContent:'center',
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            sx={{
              display: { md: "flex", xs: "none" },
              flexDirection: "row",
              height: {lg:'90%',md:'90%'},
              maxWidth: "1200px",

            }}
            
            
          >
            {data.map((x) => (
              <Grid sx={{}} item lg={4} md={4} xs={12}>
                <Link style={{
                  textDecoration:'none'
                }} to={x.link}>
                <ServiceComponent
                  title={x.title}
                  description={x.description}
                  image={x.image}
                  backColor={x.backColor}
                ></ServiceComponent>
                </Link>
              </Grid>
            ))}
          </Grid>
          </Box>
          <Box
            sx={{
              
              width: { md: "0", xs: "100%" },
            }}
          >
            <Carousel
              infinite={true}
              className="sliderClass"
              responsive={responsive}
              swipeable
              slidesToSlide={1}
              transitionDuration={20}
              autoPlaySpeed={500}
            >
              {data.map((x) => (
                <div style={{
                    marginLeft:'13%',
                    // marginRight:'7%'
                    
                }}>
                <ServiceComponent
                
                  title={x.title}
                  description={x.description}
                  image={x.image}
                  backColor={x.backColor}
                ></ServiceComponent></div>
              ))}
            </Carousel>
          </Box>
        
        </Box>
      </>
    );
}


export default SectionService